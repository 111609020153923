import { window } from '@mtsbank/ui-kit'
import { CommandType, ReportErrorOptions, ReportEventOptions } from './types'

const logEvent = (eventName: string, options?: ReportEventOptions) => {
  if (window.ineum) {
    window.ineum(CommandType.REPORT_EVENT, eventName, options)
  }
}
const logError = (error: Error | string, options?: ReportErrorOptions) => {
  if (window.ineum) {
    window.ineum(CommandType.REPORT_ERROR, error, options)
  }
}

export { logEvent, logError }
