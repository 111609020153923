import { webPushShowNotificationGtm } from '../gtm/webPush/swEvents'
import { translit } from '../gtm/translit'
import { isUuidValid, uuid } from '../generateUuid'
import { logEvent } from '../instana/instana'
import { ApiRequest } from './ApiRequest'
import {
  GetNotificationTextRsp,
  LocalStorageKey,
  LogApiEvent,
  LogEvent,
  NotificationEventInfo,
  NotificationInfoProps,
  NotificationStatus,
  PushTokenTokenTypeEnum,
  SetUserNotificationStatusRsp,
} from './types'

export class NotificationInfo {
  private readonly serviceWorkerRegistration: ServiceWorkerRegistration

  private apiRequest: ApiRequest

  private deviceId: string

  constructor(serviceWorkerRegistration: ServiceWorkerRegistration, apiRequest: ApiRequest) {
    this.serviceWorkerRegistration = serviceWorkerRegistration
    this.apiRequest = apiRequest
  }

  private getDeviceId = () => {
    let deviceId = window.localStorage.getItem(LocalStorageKey.DEVICE_ID) || ''

    if (!isUuidValid(deviceId)) {
      deviceId = uuid()
    }

    this.setDeviceId(deviceId)

    return this.deviceId
  }

  private setDeviceId = (deviceId: string) => {
    window.localStorage.setItem(LocalStorageKey.DEVICE_ID, deviceId)

    this.deviceId = deviceId
  }

  getServiceWorkerRegistration = () => this.serviceWorkerRegistration

  /**
   * Отправка pushToken из firebase в ComDomain
   *
   * @param token
   */
  sendPushToken = (token: string): Promise<{ result?: boolean }> => {
    const [deviceModel, deviceVendor] = window.navigator?.userAgent.match(/(.+?\))(.+)/).slice(1) || []

    const pushTokenData = {
      pushToken: [
        {
          appName: 'MTSMoney',
          deviceId: this.getDeviceId(),
          deviceName: 'web',
          deviceModel: deviceModel || 'undefined',
          deviceVendor: deviceVendor ? deviceVendor.trim().substring(0, 100) : 'undefined',
          token,
          tokenType:
            process.env.NEXT_PUBLIC_BUILD_ENV === 'production'
              ? PushTokenTokenTypeEnum.RELEASE
              : PushTokenTokenTypeEnum.DEBUG,
        },
      ],
    }

    return this.apiRequest.post(
      `${process.env.NEXT_PUBLIC_UMP_HOST}/communication-gateway/v1/push_token/web`,
      pushTokenData
    )
  }

  /**
   * Запрашивает полную информауию о пуш-уведомлении у ComDomain по {notificationId}, который ComDomain отправляет в firebase
   * (ComDomain отправляет в firebase только notificationId), к котором на стороне ComDomain привязывается вся необходимая информация в рамххах
   * какой-либо рассылки для пользовтелей через пуш-уведомления
   *
   * @param notificationId
   */
  getNotificationText = (notificationId: string): Promise<GetNotificationTextRsp> =>
    this.apiRequest.get(
      `${process.env.NEXT_PUBLIC_UMP_HOST}/communication-gateway/v1/push_notification/notification_text/${notificationId}`
    )

  /**
   * Отправляет в ComDomain статус показа пуш-уведомеления (доставлено/не доставлено)
   *
   * @param notificationId
   * @param status
   */
  setStatus = (notificationId: string, status: NotificationStatus): Promise<SetUserNotificationStatusRsp> =>
    this.apiRequest.post(
      `${process.env.NEXT_PUBLIC_UMP_HOST}/communication-gateway/v1/user_notification/set_status/${notificationId}`,
      { status }
    )

  /**
   * Отправляет ComDomain событие взаимодействия пользователя с пуш-уведомлением
   * (пользователь кликнул на пуш-уведомление)
   *
   * @param notificationId
   * @param notificationEventInfo
   */
  sendEventInfo = (notificationId: string, notificationEventInfo: NotificationEventInfo) =>
    this.apiRequest.post(
      `${process.env.NEXT_PUBLIC_UMP_HOST}/communication-gateway/v1/user_notification/event_info/${notificationId}`,
      {
        isNotificationId: true,
        ...notificationEventInfo,
      }
    )

  /**
   * Отображает пуш-уведомление пользовтаелю, отправляет в ComDomain статус показа уведомления
   * по {notificationId} заправшивается полная информация об уведомлении у ComDomain,
   * полученные данные отображаются пользователю посредство NotificationApi
   *
   * @param notificationId {string} - идентификатор пуш-сообщение, который отправил ComDomain в firebase
   */
  showNotification = (notificationId: string) => {
    logEvent(LogEvent.SHOW_NOTIFICATION, {
      meta: {
        title: 'Отображение пуш-уведомления пользователю',
        description: 'showNotification = (notificationId: string) => {}',
        details: JSON.stringify({
          notificationId,
        }),
      },
    })

    this.getNotificationText(notificationId)
      .then(({ title, text, imageData, deepLink }) => {
        const notificationInfo: NotificationInfoProps = {
          body: text,
          image: imageData?.url,
          icon: imageData?.url,
          requireInteraction: true,
          data: {
            notificationId,
            deepLink,
          },
        }

        logEvent(LogApiEvent.GET_NOTIFICATION_TEXT, {
          meta: {
            title: 'Получение данных у ComDomain для уведомления по notificationId',
            description: 'getNotificationText(notificationId)',
            details: JSON.stringify({
              notificationId,
              notificationInfo,
            }),
          },
        })

        this.serviceWorkerRegistration
          .showNotification(title, notificationInfo)
          .then(() => {
            webPushShowNotificationGtm({
              eventContent: translit(title),
              filterName: notificationInfo.data.deepLink,
            })

            logEvent(LogEvent.SW_API_SHOW_NOTIFICATION, {
              meta: {
                title: 'Отображение пуш-уведомления пользователю',
                description: 'serviceWorkerRegistration.showNotification(title, notificationInfo)',
                details: JSON.stringify({
                  title,
                  notificationInfo,
                }),
              },
            })

            return this.setStatus(notificationId, NotificationStatus.DELIVERED)
          })
          .catch((error) => {
            logEvent(`${LogEvent.SW_API_SHOW_NOTIFICATION}_error`, {
              meta: {
                title:
                  'NotificationApi: ошибка показа пуш-уведомления serviceWorkerRegistration.showNotification(title, notificationInfo)',
                details: JSON.stringify({
                  title,
                  notificationInfo,
                  error: error.stack,
                }),
              },
            })
          })
      })
      .catch((error) => {
        logEvent(LogApiEvent.GET_NOTIFICATION_TEXT, {
          meta: {
            title:
              'comDomainApi: getNotificationText(notificationId), ошибка при получении данных у ComDomain для уведомления по notificationId',
            details: JSON.stringify({
              notificationId,
              error,
            }),
          },
        })
      })
  }
}
