import { GatewayProtoOpenedRequest } from '@open-api/ump/debit-cards'

export const statusTransform = {
  APPROVED: 'Карта готова',
  OUTDATE: 'Карта готова',
  IN_PROGRESS: 'Выпускается',
  DOCUMENT_ISSUE: 'Карта готова',
  WAITING_CLIENT: 'Получить карту',
}

const statusFilter = ['DONE', 'SV_WAITING_CLIENT', 'WAITING_VIRT', 'SV_WAITING_VIRT']

export const getStatusTranform = (statusCode: string): string => statusTransform[statusCode] ?? statusCode

export const getStatusFilter = (openedRequests: GatewayProtoOpenedRequest[]): GatewayProtoOpenedRequest[] =>
  openedRequests?.filter((element) => !statusFilter.includes(element.statusCode))

export const getStatusLink = (statusCode: string, requestId: string): string => {
  if (statusCode === 'WAITING_CLIENT') {
    return `/spa/cards/delivery?requestId=${requestId}`
  }

  return `/spa/cards/signDocument?requestId=${requestId}`
}
