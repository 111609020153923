import { Dictionary } from '@reduxjs/toolkit'

export interface ValidateResult {
  result?: boolean
}

export interface UserData {
  full_name: {
    first_name: string
    last_name?: string
    middle_name?: string
  }
  ibso_id?: number
  is_fully_ident: boolean
  organizations?: []
  phone_number: string
  rbo_id: number
}

export interface AuthData {
  user_id: number
  client_id: string
  expiry: Date
  duration: number
  user_data: UserData
}

export enum AuthType {
  ANONYMOUS = 1,
  SIMPLE_IDENTIFIED = 2,
  FULL_IDENTIFIED = 3,
  BANK_CLIENT = 4,
}

export enum ProfileType {
  // "profile:type" (для старых версий профиля):
  'default' = 'na',
  'Мобильная связь' = 'real',
  'Фиксированная связь и СТВ' = 'real',
  'Фиксированная связь' = 'real',
  'Безопасное детство' = 'real',
  'Виртуальный номер' = 'virt',
  'МТС Коннект' = 'virt',
  'Госуслуги' = 'other',
  'mgts' = 'other',
  'mrmsk' = 'other',
  'mgts, mrmsk' = 'other',
  'Домашний интернет' = 'other',
}

// type для новых типов профилей
export enum NewProfileType {
  'MOBILE' = 'mobile',
  'FIX' = 'fix',
  'LBSV' = 'lbsv',
  'VIRTUAL' = 'virtual',
  'GS' = 'gs',
  'mgts' = 'mgts',
  'mrmsk' = 'mrmsk',
  'mgts, mrmsk' = 'mgts, mrmsk',
  'IOT' = 'iot',
}

export enum AccountType {
  'Мобильная связь' = 'mobile',
  'Фиксированная связь и СТВ' = 'stv',
  'Фиксированная связь' = 'fix',
  'Виртуальный номер' = 'mobile',
  'МТС Коннект' = 'mobile',
  'Безопасное детство' = 'mobile',
  'Госуслуги' = 'other_operators',
  'mgts' = 'mgts',
  'mrmsk' = 'mgts',
  'mgts, mrmsk' = 'mgts',
  'Домашний интернет' = 'fix',
}

export interface UserProfile extends Dictionary<string | AuthType> {
  mobilePhone?: string
  authType?: AuthType
  userId?: string
}

export interface Headers {
  Authorization?: string
}
