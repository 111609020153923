import { ModalConfig } from '@utils/webPush/types'

export const getModalConfig = (hasActions: boolean): ModalConfig => {
  const sketchImage = hasActions ? 'safari-scetch.png' : 'scetch.png'
  const size = {
    width: hasActions ? 81 : 79,
    height: hasActions ? 53 : 106,
  }

  return {
    mobile: {
      title: 'Разрешите уведомления',
      description: 'Напомним о\u00A0платежах и\u00A0сообщим о\u00A0других операциях',
      img: {
        src: `${process.env.NEXT_PUBLIC_STATIC_HOST}/images/push-notifications/${sketchImage}`,
        size,
      },
      buttons: {
        accept: {
          title: 'Хорошо',
          variant: 'primary',
        },
        decline: {
          title: 'Позже',
          variant: 'secondary',
        },
      },
    },
    desktop: {
      title: 'Разрешите уведомления',
      description: 'Отправляем сообщения о\u00A0платежах, переводах, других операциях и\u00A0акциях',
      img: {
        src: `${process.env.NEXT_PUBLIC_STATIC_HOST}/images/push-notifications/${sketchImage}`,
        size,
      },
      buttons: {
        accept: {
          title: 'Хорошо',
          variant: 'primary',
        },
        decline: {
          title: 'Позже',
          variant: 'secondary',
        },
      },
    },
  }
}
