/* eslint-disable react/no-danger */
import React, { useMemo, useRef, useState } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { Provider } from 'react-redux';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import { StyleProvider, useDidMount, ToastProvider } from '@mtsbank/ui-kit';
import { GibHOC } from '@root/hoc/GibHOC';
import '../index.css';
// TODO: по какой-то причине стили для тултипа из ui-kit
// не попадают в билд из коробки.
// Вероятно нужны допилы в настройки билда некста, пока не разобрался
import 'tippy.js/index.css';
import { store } from '@root/store';
import { gtmNoScript, gtmScript } from '@utils/gtm/gtmScript';
import { Chat } from '@components/Chat/Chat';
import { aftStore } from '@utils/axios/aftInterceptor';
import { INSTANA_SCRIPT, INSTANA_SCRIPT_URL } from '@root/utils/instanaScript';
import { authHelper } from '@utils/authHelper/AuthHelper';
import { STATIC_HOST_ORIGIN } from '@root/constants';
import { Layout } from '@root/layouts/Layout';
import { gibInit, GROUPIB_SCRIPT_URL } from '@utils/groupIBService';
import { YaContextCb } from '@components/YaContextCb/YaContextCb';
type TAppProps<P = any> = {
  pageProps: P;
} & Omit<AppProps<P>, 'pageProps'>;
const App = ({
  Component,
  pageProps
}: TAppProps) => {
  const [isAuth, setIsAuth] = useState(false);
  const router = useRouter();
  const initialRouteChange = useRef<boolean>(false);
  const [isGibInit, setIsGibInit] = useState(false);
  useDidMount(() => {
    gibInit(setIsGibInit);
  });
  useDidMount(() => {
    const handleRouteChange = () => {
      if (!initialRouteChange.current) {
        initialRouteChange.current = true;
      } else {
        aftStore.autoClean();
      }
      return true;
    };
    if (authHelper.isAuthenticated()) {
      setIsAuth(true);
    }
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  });
  const serviceInfo = useMemo(() => isAuth ? {
    manifest: '/spa/MTCbank.webmanifest',
    name: 'МТС Банк',
    faviconPath: `${STATIC_HOST_ORIGIN}/img/MTCbank`,
    faviconSVG: `${STATIC_HOST_ORIGIN}/img/faviconMtsOplata.svg`
  } : {
    manifest: '/spa/MTCoplata.webmanifest',
    name: 'МТС Оплата',
    faviconPath: `${STATIC_HOST_ORIGIN}/img/MTCoplata`,
    faviconSVG: `${STATIC_HOST_ORIGIN}/img/faviconMtsOplata.svg`
  }, [isAuth]);
  return <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{serviceInfo.name}</title>
        <meta name="description" content={serviceInfo.name} />
        <link rel="shortcut icon" sizes="48x48" href={`${serviceInfo.faviconPath}/favicon.ico`} />
        <link rel="apple-touch-icon" sizes="180x180" href={`${serviceInfo.faviconPath}/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${serviceInfo.faviconPath}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${serviceInfo.faviconPath}/favicon-16x16.png`} />
        <link rel="mask-icon" href={serviceInfo.faviconSVG} />
        <link rel="manifest" href={serviceInfo.manifest} />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <Provider store={store}>
        <GibHOC isGibInit={isGibInit} isAuth={isAuth}>
          <StyleProvider>
            <ToastProvider className="toastProvider">
              <Layout withNps={pageProps.withNps}>
                <Component {...pageProps} />
              </Layout>
            </ToastProvider>
          </StyleProvider>
        </GibHOC>
      </Provider>
      <Chat />
      <YaContextCb />
      <Script id="instana" dangerouslySetInnerHTML={{
      __html: INSTANA_SCRIPT
    }} />
      <Script defer crossOrigin="anonymous" src={INSTANA_SCRIPT_URL} />
      {!pageProps.isDisabledGib && <Script defer crossOrigin="anonymous" src={GROUPIB_SCRIPT_URL} />}

      <Script defer id="gtm-src" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: gtmScript
    }} />

      <noscript dangerouslySetInnerHTML={{
      __html: gtmNoScript
    }} />
    </>;
};
export default App;