import React, { useCallback, useEffect, useState } from 'react'
import { useBreakpoint, window } from '@mtsbank/ui-kit'
import { NotificationPopup } from '@utils/webPush/NotificationPopup'
import { LocalStorageKey } from '@utils/webPush/types'
import { DELAY_TIME, popupManager } from './popupManager'
import { webPush } from './webPush'

export const WebPushNotification = () => {
  const [open, setOpen] = useState(false)
  const {
    close,
    setPopupTime,
    isPopupAvailable,
    clearPopupTime,
    hasActions,
    addResetPopupListeners,
    removeResetPopupListeners,
  } = popupManager

  const { isMobile, isTablet } = useBreakpoint()
  const isActionsAvailable = hasActions() || isMobile || isTablet

  const handleClose = useCallback(() => {
    setOpen(false)
    setPopupTime(LocalStorageKey.POPUP_SHOW_TIME, Date.now())
  }, [setPopupTime])

  const handleAccept = useCallback(() => {
    webPush.init()
    close()
  }, [close])

  const popupActions = isActionsAvailable
    ? {
        onAccept: handleAccept,
        onDecline: close,
      }
    : { onClose: close }

  useEffect(() => {
    addResetPopupListeners()

    Object.assign(popupManager, {
      close: handleClose,
    })

    let timerId: number

    if (webPush.isPermissionGranted()) {
      webPush.init()
    }

    if (isPopupAvailable) {
      timerId = window.setTimeout(() => {
        setOpen(true)

        if (!isActionsAvailable) {
          webPush.init()
        }
      }, DELAY_TIME)
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId)
      }

      removeResetPopupListeners()
    }
  }, [
    addResetPopupListeners,
    clearPopupTime,
    handleClose,
    isActionsAvailable,
    isPopupAvailable,
    removeResetPopupListeners,
  ])

  if (!isPopupAvailable()) {
    return null
  }

  return open && <NotificationPopup {...popupActions} />
}
