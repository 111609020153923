import { Dictionary } from '@reduxjs/toolkit'

export enum DataLayerEvents {
  SHOW_POPUP,
  CLICK_RAZRESHIT_ISPOLZOVANIE_PUSHEI_POPUP,
  CLICK_NE_RAZRESHAT_ISPOLZOVANIE_PUSHEI_POPUP,
  CLICK_BLOKIROVAT_SISTEMNOE_OKNO,
  CLICK_RAZRESHIT_SISTEMNOE_OKNO,
  SHOW_PUSH,
  CLICK_PUSH,
}

export type ConfigParamsByEvent = Record<DataLayerEvents, Dictionary<string>>

export enum EventLabel {
  POPUP = 'pop_up',
  RAZRESHIT_ISPOLZOVANIE_PUSHEI = 'razreshit_ispolzovanie_pushei',
  NE_RAZRESHAT_ISPOLZOVANIE_PUSHEI = 'ne_razreshat_ispolzovanie_pushei',
  BLOKIROVAT = 'blokirovat',
  RAZRESHIT = 'razreshit',
  PUSH = 'push',
}

export enum EventName {
  MTS_DENGI_LK = 'mtsDengiLK',
}

export enum EventCategory {
  WEB_PUSH = 'web_push',
}

export enum EventPageType {
  pageType = 'main_lk_mtsdengi',
}

export const eventLabel: Dictionary<string> = {
  popup: 'pop_up',
}

export enum EventContent {
  POPUP = 'pop_up',
  SISTEMNOE_OKNO = 'sistemnoe_okno',
}

export interface DataLayer {
  eventLabel?: EventLabel
  eventContent?: EventContent | string
  filterName?: string
}
