export enum EDataLayerEvents {
  SHOW_STEP = 'show_step',
  INPUT = 'INPUT',
  ERROR_INPUT = 'ERROR_INPUT',
  PREFILL = 'PREFILL',
  BUTTON = 'BUTTON',
  CONFIRM = 'CONFIRM',
  REJECT = 'REJECT',
  STORIES = 'STORIES',
  ERROR = 'ERROR',
  SERVICE_ERROR = 'SERVICE_ERROR',
  ELEMENT_CLICK = 'ELEMENT_CLICK',
  ELEMENT_CHANGE = 'ELEMENT_CHANGE',
  ELEMENT_SHOW = 'ELEMENT_SHOW',
  LINK_CLICK = 'LINK_CLICK',
  SWITCHER_SWITCH = 'SWITCHER_SWITCH',
  MTS_PAGE_VIEW = 'MTS_PAGE_VIEW',
  SHOW_MODAL = 'SHOW_MODAL',
  SCREEN_SHOW = 'SCREEN_SHOW',
}

export enum EDataLayerGroups {
  CONVERSIONS = 'conversions',
  INTERACTIONS = 'interactions',
  NON_INTERACTIONS = 'non_interactions',
}

export enum EDataLayerActions {
  BUTTON_CLICK = 'button_click',
  SWITCHER_SWITCH = 'switcher_switch',
  ERROR_SHOW = 'error_show',
  LINK_CLICK = 'link_click',
  ELEMENT_SHOW = 'element_show',
  ELEMENT_CLICK = 'element_click',
  ELEMENT_CHANGE = 'element_change',
  ELEMENT_STATUS = 'element_status',
  CONFIRMED = 'confirm',
  FORM_CONFIRMED = 'form_confirmed',
  REJECTED = 'reject',
  FORM_REJECTED = 'form_rejected',
  BANNER_SHOW = 'banner_show',
  SCREEN_SHOW = 'screen_show',
  FORM_SHOW = 'form_show',
  FORM_ADD = 'form_add',
  SUCCESS = 'form_send',
  PAGE_VIEW = 'mtsPageview',
}

export enum EDataLayerProductId {
  KREDIT_NAL = 'kredit_nalichnymi',
  NAN = 'nan',
  KREDIT_REFINANSIROVANIE = 'kredit_refinansirovanie',
  NCPK = 'ncpk',
  CC_WEEKEND_KARMA = 'cc_weekend_karma',
  CREDIT_HOLIDAYS = 'kredit_kreditnye_kanikuly',
  COLLATERAL = 'car-valuetion',
}

export type TDataLayerObj = {
  actionGroup: EDataLayerGroups
  eventCategory: string
  ref: string
  screenName: string
  eventLabel: string
  buttonLocation: string | null
  region: string | null
  bannerName: string | null
  bannerId: string | null
  productName: string
  productId: string
  eventContent: string | null
  eventContext: string | null
  eventValue: null | string
  grclientId: string | null
  userAuth: string
  touchPoint: TTouchPoint
  userId: null
  CD1?: string
  abonent?: string | null
  eventAction?: EDataLayerActions
  event?: string
  ph?: string
  prCat?: string
  filterName?: string
  intId?: string
  pageType?: string | null
  formId?: string | null
}

export enum EPrefillFieldName {
  FIO = 'clientName',
  BIRTHDAY = 'birthDateV2',
  EMAIL = 'email',
  GENDER = 'sex',
  PASSPORT = 'clientDoc',
  DATA_ISSUE = 'issueDateV2',
  CODE_DEP = 'issueOrgCode',
  DEP = 'issueOrgName',
  REG_ADDRESS = 'registrationAddress',
  ADDRESS_MATCH = 'isAddressesMatch',
  FACT_ADDRESS = 'residenceAddress',
  USA_TAX = 'taxUSAFlag',
  FOREIGN_RES = 'gatkaFlg',
  ORG_NAME = 'employmentFullName',
  NAME_POSITIOON = 'position',
  ADDRESS_ORG = 'orgActualAddress',
  ORG_PHONE = 'employmentPhone',
  MART_STATUS = 'martialStatus',
  DEPENDETS = 'dependents',
}

export enum EPrefillFieldNameFA {
  PERSON = 'fullPerson',
  BIRTHDAY = 'birthDate',
  EMAIL = 'email',
  PHONE = 'mobilePhone',
  PASSPORT = 'clientDoc',
  DATA_ISSUE = 'issueDateV2',
  CODE_DEP = 'issueOrgCode',
  DEP = 'issueOrgName',
  REG_ADDRESS = 'registrationAddress',
  FACT_ADDRESS = 'residenceAddress',
  WORK_ADDRESS = 'orgActualAddress',
  WORK_INN = 'orgINN',
  WORK_NAME = 'orgName',
  WORK_PHONE = 'employmentPhone',
  WORK_POSITION = 'position',
  MARTIAL_STATUS = 'martialStatus',
  DEPENDENTS = 'dependents',
}

export type DefaultKeys = 'event' | 'productId' | 'eventCategory' | 'productName'

type TEventRequiredFields = 'eventCategory' | 'eventLabel' | 'productName' | 'productId' | 'productName'

export type TEventObject<T> = Partial<TDataLayerObj> &
  Required<Pick<TDataLayerObj, TEventRequiredFields>> & {
    getter?: (values: T) => T[keyof T]
  }

export type TInternalFields =
  | 'touchPoint'
  | 'region'
  | 'productId'
  | 'productName'
  | 'region'
  | 'actionGroup'
  | 'eventCategory'
  | 'userAuth'
  | 'screenName'
  | 'buttonLocation'
  | 'bannerName'
  | 'bannerId'
  | 'eventContent'
  | 'eventContext'
  | 'eventValue'
  | 'grclientId'
  | 'userId'
  | 'ref'
  | 'ph'
  | 'abonent'

export enum EDataLayerStepName {
  CALCULATOR = 'step_parametry_credita',
  LOAN_PURPOSE = 'step_cel_credita_i_dohod',
  LOAN_PURPOSE_SHORT = 'step_dohod_i_data_platezha',
  BOXES = 'step_korobochnie_uslugi',
  PERSONAL_DATA = 'step_personalnye_dannye',
  PASSPORT_DATA = 'step_pasportnye_dannye',
  ORGANIZATION_DATA = 'step_o_rabote',
  SMS_CONFIRMATION = 'sms_confirmation',
  STORIES = 'stories',
}

export enum EDataLayerScreenName {
  NCPK_STEP_1 = '/spa/ncpk/step_1',
  NCPK_STEP_2 = '/spa/ncpk/step_2',
  NCPK_STEP_3 = '/spa/ncpk/step_3',
  NCPK_STEP_4 = '/spa/ncpk/step_4',
  NCPK_STEP_1_V1 = '/spa/ncpk/step_1/v1',
  NCPK_STEP_2_V1 = '/spa/ncpk/step_2/v1',
  NCPK_STEP_3_V1 = '/spa/ncpk/step_3/v1',
  NCPK_STEP_4_V1 = '/spa/ncpk/step_4/v1',
  NCPK_STEP_VARIATY = '/spa/ncpk/step_',
  NCPK_SUCCESS = '/spa/ncpk/success',
  NCPK_SUCCESS_V1 = '/spa/ncpk/success/v1',
  NCPK_REJECT = '/spa/ncpk/reject',
  NCPK_ERROR = '/spa/ncpk/error',
  NCPK_SMS_CONFIRM = '/spa/ncpk/sms_confirmation',
  NCPK_SMS_CONFIRM_V1 = '/spa/ncpk/sms_confirmation/v1',
  FA_STEP_VARIATY = '/spa/loan/fa/step_',
  FA_CALCULATOR = '/spa/loan/fa/step_parametry_credita',
  FA_LOAN_PURPOSE = '/spa/loan/fa/step_cel_kredita_i_dohod',
  FA_LOAN_PURPOSE_SHORT = '/spa/loan/fa/dohod_i_data_platezha',
  FA_BOXES = '/spa/loan/fa/step_korobochnie_uslugi',
  FA_PERSONAL_DATA = '/spa/loan/fa/step_personalnye_dannye',
  FA_PASSPORT_DATA = '/spa/loan/fa/step_pasportnye_dannye',
  FA_ORGANIZATION_DATA = '/spa/loan/fa/step_o_rabote',
  FA_SMS_CONFIRMATION = '/spa/loan/fa/sms_confirmation',
  FA_SIGN = '/spa/loan/fa/ekran_vydachi',
  FA_STORIES = '/spa/loan/fa/stories',
  FA_SUCCESS = '/spa/loan/fa/credit_order_success',
  FA_REJECT = '/spa/loan/fa/reject',
  FA_ERROR = '/spa/loan/fa/error',
  LOAN_READY = '/spa/loan/fa/kredit_gotov_k_vydache',
  LOAN_SIGN = '/spa/loan/fa/dogovor_kreditovaniya',
  SMS_CONFIRM = '/spa/loan/fa/sms_podtverzhdenie',
  REFUSAL_REASON = '/spa/loan/fa/fiks_prichin',
  CAR_VALUATION = '/spa/car-valuetion',
}

export type TUseDataLayerProps<T> = {
  eventDefaults: Record<DefaultKeys, string>
  layerSettings?: Partial<Record<EDataLayerEvents, Omit<TEventObject<T>, TInternalFields>>>
  isWebView?: boolean
}

export type TDispatchFunctionProps = {
  event: EDataLayerEvents
  rboId: number
  issueId: string | number
  eventLabel?: string
  CD1?: string
  values?: string | number
  error?: string
  isPrefill?: boolean
  eventContent?: string
  screenName?: string
  buttonLocation?: string
  eventAction?: EDataLayerActions
  ph?: string
  filterName?: string
  intId?: string
  abonent?: string
  eventValue?: string
  eventContext?: string
  pageType?: string
  formId?: string
  eventCategory?: string
  touchPoint?: TTouchPoint
  actionGroup?: EDataLayerGroups
}

export type TDispatchFunction = ({
  event,
  eventLabel,
  rboId,
  issueId,
  values,
  error,
  isPrefill,
  eventContent,
  screenName,
  buttonLocation,
  eventAction,
  ph,
  pageType,
  formId,
  touchPoint,
}: TDispatchFunctionProps) => void

export type TUseDataLayerReturnType = {
  dataLayerDispatcher: TDispatchFunction
}

export type TTouchPoint = 'Payment_webview' | 'Payment' | 'web' | 'webview'
