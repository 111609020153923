import { configParamsByEvent } from '@utils/gtm/webPush/config'
import { sendGmtEvent } from '../baseFunctions'
import { EventCategory, EventName, EventPageType, DataLayer, DataLayerEvents } from './types'

export const webPushSendGtm = (event: DataLayerEvents, dataLayer: DataLayer) =>
  sendGmtEvent({
    event: EventName.MTS_DENGI_LK,
    eventCategory: EventCategory.WEB_PUSH,
    eventLabel: dataLayer.eventLabel,
    screenName: window.location.pathname,
    pageType: EventPageType.pageType,
    ...configParamsByEvent[event],
  })
