import { AuthType } from '@utils/authHelper/types'

import { RouteProps } from '@root/types/route'

import {
  ARREST_PATH,
  EXPIRED_PATH,
  EXPIRED_DEBT_PATH,
  CREDIT_HOLIDAYS_PATH,
  CREDIT_HOLIDAYS_FORM_PATH,
  EARLY_REPAYMENT_PATH,
  FINAL_REQUIREMENT_PATH,
  FREE_CONNECTION_CARD_ID_COUNTER_PATH,
  FREE_CONNECTION_CARD_ID_PATH,
  FREE_CONNECTION_PATH,
  GOSUSLUGI_INFO_PATH,
  GOSUSLUGI_PATH,
  HISTORY_OPERATIONS_PATH,
  ONBOARDING_PATH,
  REFERENCES_PATH,
  POS_PATH,
  REFILL_PATH,
  LOAN_ISSUE_PATH,
  SBP_PATH,
  SBP_RESULT_PATH,
  LOAN_RESTRUCT_PATH,
  MAIN_PATH,
  SERVICE_PATH,
  SPECIFIC_REFERENCE_PATH,
  LIMITS_PATH,
  WALLET_INFO,
  PHONE_BILL,
  PERSONAL_OFFER_PATH,
  TRANSFER_CARD_TO_CARD,
  ACCOUNT_OPENING_PATH,
  REPLENISHMENT_WALLET_ACCOUNT,
  LOAN_FA_PATH,
  NCPK_PATH,
  NCPK_PATH_V1,
  CARDS_OPEN_PATH,
  PROMOTION_APPLY,
  DEPOSIT_PATH,
  DEPOSIT_DETAILS_PATH,
  DEPOSIT_CLOSE_PATH,
  DEPOSIT_CONDITIONS_PATH,
  DEPOSIT_CLOSE_WITH_STEP_PATH,
  SMS_DELIVERY_PATH,
  CARDS_REQUEST_SIGNER,
  CARD_DISCOUNT_INFO_PATH,
  PARTNERS,
  WAGE_BENEFITS_PATH,
  WAGE_HASH_PAN_ID,
  LIMIT_PLUS_PATH,
  DEPOSITS_OPEN_PATH_FOR_AUTH,
  CARD_REQUEST_SIGNER_MLC_PATH,
  CAR_VALUATION_PATH,
  PREMIUM_SERVICES,
  PREMIUM_PATH,
  LOAN_PATH,
  SPA_EARLY_REPAYMENT_PATH,
  DEPOSIT_REFERENCES_PATH,
  DEPOSIT_REFERENCES_WITH_STEP_PATH,
  MAIN_OFFERS_PATH,
  CARDS_ADD_PATH,
  CARDS_DELIVERY,
  PASSPORT_UPDATE_PATH,
  AGREEMENT_LOAN_USER,
  LOAN_ISSUE_LOAN_PATH,
  CC_LOYALTY_APPLICATION_SIGNING,
  SERVICE_LIST_PATH,
  SUBSCRIPTION_FINTECH,
  INVOICES_PATH,
  SPA_CREDIT_CARMA,
  PHONE_TRANSFER_PATH,
  CARDS_OPEN_WEBVIEW,
  NCPK_PATH_V2,
  CC_LOYALTY_CLOSE_APPLICATIONS,
} from './constants'

const routes: RouteProps[] = [
  {
    path: MAIN_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: GOSUSLUGI_PATH,
    authType: AuthType.BANK_CLIENT,
    accessInfo: {
      title: 'Подтвердить запись не получится',
      message:
        'Мы\u00A0не\u00A0нашли вас среди клиентов МТС Банка. Возможно, вы\u00A0ошиблись в\u00A0номере телефона или выбрали не\u00A0тот банк.',
      button: {
        label: 'Вернуться на\u00A0Госуслуги',
        link: 'https://esia.gosuslugi.ru/registration/',
      },
    },
  },
  {
    path: ONBOARDING_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: REFILL_PATH,
    authType: AuthType.FULL_IDENTIFIED,
    accessInfo: {
      title: 'Требуется полная идентификация',
      message: '',
    },
  },
  {
    path: GOSUSLUGI_INFO_PATH,
  },
  {
    path: ARREST_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: EXPIRED_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: EXPIRED_DEBT_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: SERVICE_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: REFERENCES_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: POS_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: SPECIFIC_REFERENCE_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: EARLY_REPAYMENT_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: LOAN_ISSUE_LOAN_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: LOAN_ISSUE_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: LOAN_FA_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: CREDIT_HOLIDAYS_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: CREDIT_HOLIDAYS_FORM_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: LOAN_RESTRUCT_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: SBP_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: SBP_RESULT_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: `${HISTORY_OPERATIONS_PATH}/[[...params]]`,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: MAIN_OFFERS_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: LOAN_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: SPA_EARLY_REPAYMENT_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: FINAL_REQUIREMENT_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: PROMOTION_APPLY,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: LIMITS_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: WALLET_INFO,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: PHONE_BILL,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: PERSONAL_OFFER_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: TRANSFER_CARD_TO_CARD,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: ACCOUNT_OPENING_PATH,
    authType: AuthType.FULL_IDENTIFIED,
  },
  {
    path: REPLENISHMENT_WALLET_ACCOUNT,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: NCPK_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: NCPK_PATH_V1,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: NCPK_PATH_V2,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: CARDS_OPEN_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: CARDS_REQUEST_SIGNER,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: CARDS_ADD_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: CARDS_OPEN_WEBVIEW,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: CARDS_DELIVERY,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: FREE_CONNECTION_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: FREE_CONNECTION_CARD_ID_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: FREE_CONNECTION_CARD_ID_COUNTER_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: CARD_DISCOUNT_INFO_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: DEPOSIT_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: DEPOSIT_DETAILS_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: DEPOSIT_CLOSE_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: DEPOSIT_CONDITIONS_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: DEPOSIT_CLOSE_WITH_STEP_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: DEPOSIT_REFERENCES_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: DEPOSIT_REFERENCES_WITH_STEP_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: SMS_DELIVERY_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: DEPOSITS_OPEN_PATH_FOR_AUTH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: WAGE_BENEFITS_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: WAGE_HASH_PAN_ID,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: PARTNERS,
  },
  {
    path: CARD_REQUEST_SIGNER_MLC_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: CAR_VALUATION_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: LIMIT_PLUS_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: PREMIUM_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: PREMIUM_SERVICES,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: PASSPORT_UPDATE_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: AGREEMENT_LOAN_USER,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: CC_LOYALTY_APPLICATION_SIGNING,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: SERVICE_LIST_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: SUBSCRIPTION_FINTECH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: INVOICES_PATH,
    authType: AuthType.BANK_CLIENT,
  },
  {
    path: SPA_CREDIT_CARMA,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: PHONE_TRANSFER_PATH,
    authType: AuthType.ANONYMOUS,
  },
  {
    path: CC_LOYALTY_CLOSE_APPLICATIONS,
    authType: AuthType.ANONYMOUS,
  },
]

export { routes }
