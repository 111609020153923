import { logEvent } from '../instana/instana'

export class ApiRequest {
  private options: RequestInit

  private readonly accessToken: string

  private fetcher = <T>(url: string, options?: RequestInit) =>
    fetch(url, {
      ...this.options,
      ...options,
    }).then((response) => {
      if (response.status === 200) {
        return response.json() as Promise<T>
      }

      throw new Error('Fetch error')
    })

  constructor() {
    if (typeof window !== 'undefined') {
      this.accessToken = window.localStorage.getItem('access_token')
    }

    logEvent('webPush_ApiRequest', {
      meta: {
        title: 'ApiRequest: constructor()',
        details: JSON.stringify({
          accessToken: this.accessToken,
          buildEnv: typeof window !== 'undefined' && process.env.NEXT_PUBLIC_BUILD_ENV,
        }),
      },
    })

    this.options = {
      headers: {
        'Client-id':
          typeof window !== 'undefined' && window.location.host.includes('stage')
            ? 'mts-money-web-mtsid-stage'
            : 'mts-money-web-mtsid',
        ...(this.accessToken && { Authorization: `Bearer ${this.accessToken}` }),
      },
    }
  }

  public get = (url: string, options?: RequestInit) => this.fetcher(url, options)

  public post = <T>(url: string, data: T, options?: RequestInit) =>
    this.fetcher(url, {
      method: 'POST',
      body: JSON.stringify(data),
      ...options,
    })

  public get headers() {
    return this.options.headers
  }

  public set headers(headers: HeadersInit) {
    this.options = { ...this.options, headers }
  }
}
