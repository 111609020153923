/**
 * Функция генерации uuid
 * @returns {string} uuid
 */
export function uuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (templateSymbol) => {
    const randomNumber = (Math.random() * 16) | 0

    const uuid = templateSymbol === 'x' ? randomNumber : (randomNumber & 0x3) | 0x8

    return uuid.toString(16)
  })
}

export const isUuidValid = (uuid: string) =>
  Boolean(uuid.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/gi))
