import { ReactNode } from 'react'
import { HeadingProps, TextProps } from '@mtsbank/ui-kit'

export interface NoticeProps {
  fluid?: boolean
  type?: NoticeType
  icon?: string
  title?: string
  message?: string
  messageColor?: string
  headingSize?: HeadingProps['size']
  headingValue?: HeadingProps['h']
  mobHeadingValue?: HeadingProps['h']
  iconSize?: number
  mobIconSize?: number
  marginTop?: string | number
  marginTopDesktop?: string | number
  isRedirectButtonToMainPage?: boolean
  children?: ReactNode
  customMargin?: HeadingProps['customMargin']
  font?: TextProps['font']
}

export enum NoticeType {
  SUCCESS = 'success',
  SUPER_SUCCESS = 'super_success',
  SUPER_INFO = 'super_info',
  WARNING = 'warning',
  SUPER_WARNING = 'super_warning',
  ERROR = 'error',
  SUPER_ERROR = 'super_error',
  WAIT = 'wait',
  SUPER_WAIT = 'super_wait',
}

export enum UiKitIcons {
  SUCCESS = 'icon-80/Success',
  SUPER_SUCCESS = 'icon-80/SuperSuccess',
  SUPER_INFO = 'icon-80/SuperInfo',
  WARNING = 'icon-80/Warning',
  SUPER_WARNING = 'icon-80/SuperWarning',
  ERROR = 'icon-80/Fail',
  SUPER_ERROR = 'icon-80/SuperFail',
  WAIT = 'icon-80/Wait',
  SUPER_WAIT = 'icon-80/SuperWait',
}

export enum SmallUiKitIcons {
  SUCCESS = 'icon-24/Success',
  SUPER_SUCCESS = 'icon-24/SuperSuccess',
  SUPER_INFO = 'icon-24/SuperInfo',
  WARNING = 'icon-24/Warning',
  SUPER_WARNING = 'icon-24/SuperWarning',
  ERROR = 'icon-24/Fail',
  SUPER_ERROR = 'icon-24/SuperFail',
  WAIT = 'icon-24/Wait',
}
