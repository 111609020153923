import { window } from '@mtsbank/ui-kit'
import { LocalStorageKey } from '@root/layouts/types'

export const addToHomeScreenNotification = (isMobileResolution: boolean) => {
  const isNotificationShowed = window.localStorage.getItem(LocalStorageKey.ADD_TO_HOME_SCREEN_NOTIFICATION)

  if (isNotificationShowed || !isMobileResolution) {
    return
  }

  const userAgent = window.navigator?.userAgent
  const isSafari = Boolean(userAgent.includes('Safari') && !userAgent.includes('Chrome'))
  const title = 'Добавьте сайт на\u00A0домашний экран'
  const message = isSafari
    ? 'Для этого нажмите на\u00A0кнопку Поделиться и\u00A0затем\u00A0На экран \u00ABДомой\u00bb'
    : 'Для этого откройте меню браузера (его можно вызвать нажатием сверху справа на\u00A0⋮), а\u00A0затем Добавить на\u00A0гл. экран'

  window.localStorage.setItem(LocalStorageKey.ADD_TO_HOME_SCREEN_NOTIFICATION, '1')

  return { title, message }
}
