import { DataLayer, EventCategory, EventLabel, EventName, EventPageType } from './types'

// todo в sw не резиловятся импорты алиасов, поэтому полностью перенесён конфиг рамзетки
export const webPushShowNotificationGtm = (dataLayer?: DataLayer) => {
  if (typeof window !== 'undefined') {
    const eventConfig = {
      event: EventName.MTS_DENGI_LK,
      eventCategory: EventCategory.WEB_PUSH,
      eventAction: 'element_show',
      eventLabel: EventLabel.PUSH,
      eventValue: null,
      clientId: null,
      userId: null,
      grclientId: null,
      userAuth: null,
      abonent: null,
      projectName: null,
      screenName: null,
      eventContent: dataLayer.eventContent,
      eventContext: null,
      buttonLocation: null,
      filterName: dataLayer.filterName,
      pageType: EventPageType.pageType,
      actionGroup: 'non_interactions',
      prCat: null,
      productName: null,
      productId: null,
      intId: null,
      formId: null,
      bannerName: null,
      bannerId: null,
      regionId: null,
      region: null,
      ph: null,
      touchPoint: null,
      ref: null,
    }

    window.dataLayer.push(eventConfig)
  }
}
