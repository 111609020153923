// tslint:disable
/**
 * dbo-loan-issue
 * MTS Bank / MTS IT
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance, AxiosResponse } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, RequestArgs, BaseAPI, RequiredError } from './base'
import {
  CountriesResponse,
  AdditionalFieldsResponse,
  DataClientResponse,
  DataClientSaveResponse,
  TransferCreateResponse,
} from '@reducers/abroad/types'

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string
  options: any
}

/**
 *
 * @export
 * @interface Currency
 */
export interface Currency {
  /**
   *
   * @type {string}
   * @memberof Currency
   */
  ISOCode?: string
  /**
   *
   * @type {string}
   * @memberof Currency
   */
  currencyCode?: string
  /**
   *
   * @type {string}
   * @memberof Currency
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Currency
   */
  imageRef?: string
  /**
   *
   * @type {string}
   * @memberof Currency
   */
  nameCyrillic?: string
  /**
   *
   * @type {string}
   * @memberof Currency
   */
  nameLat?: string
  /**
   *
   * @type {string}
   * @memberof Currency
   */
  symbol?: string
}

/**
 *
 * @export
 * @interface Countries
 */
export interface Countries {
  /**
   *
   * @type {string}
   * @memberof Countries
   */
  ISOCode?: string
  /**
   *
   * @type {string}
   * @memberof Countries
   */
  alfa2Code?: string
  /**
   *
   * @type {string}
   * @memberof Countries
   */
  alfa3Code?: string
  /**
   *
   * @type {string}
   * @memberof Countries
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof Countries
   */
  nameCyrillic?: string
  /**
   *
   * @type {string}
   * @memberof Countries
   */
  nameLat?: string
  /**
   *
   * @type {string}
   * @memberof Countries
   */
  nameLocal?: string
  /**
   *
   * @type {string}
   * @memberof Countries
   */
  imageRef?: string
  /**
   *
   * @type {string}
   * @memberof Countries
   */
  defaultCurrency?: string
  /**
   *
   * @type {Currency[]}
   * @memberof Countries
   */
  currencies?: Currency[]
}

/**
 * MultitransferApi - axios parameter creator
 * @export
 */
export const MultitransferApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary получение списка стран
     * @param {'v1'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCountriesList(version: 'v2', options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTemplates.'
        )
      }
      const localVarPath = `/multitransfer/multitransfer-ref/{version}/countries`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary получение списка стран
     * @param {'v1'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrencyCountries(version: 'v2', options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTemplates.'
        )
      }
      const localVarPath = `/multitransfer/multitransfer-directions/{version}/countries`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary поиск досье клиента в БД
     * @param {'v1'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientData(version: 'v2', options: any = {}): RequestArgs {
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTemplates.'
        )
      }
      const localVarPath = `/multitransfer/multitransfer-client/{version}/clients/search`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary позволяет определить дополнительные параметры для совершения перевода
     * @param {'v1'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAdditionalFields(fieldsReq, options: any = {}): RequestArgs {
      let version = 'v2'
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTemplates.'
        )
      }
      const localVarPath = `/multitransfer/multitransfer-directions/{version}/fields`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof fieldsReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'

      localVarRequestOptions.data = needsSerialization ? fieldsReq : ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Возвращает список СДП и ТСП с рассчитанной комиссией перевода
     * @param {'v1'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionTransfer(commssionReq, options: any = {}): RequestArgs {
      let version = 'v2'
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTemplates.'
        )
      }
      const localVarPath = `/multitransfer/multitransfer-fee-calc/{version}/commissions`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}

      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      const needsSerialization =
        typeof commssionReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'

      localVarRequestOptions.data = needsSerialization ? commssionReq : ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Возвращает список СДП и ТСП с рассчитанной комиссией перевода
     * @param {'v1'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommissionV3Transfer(commssionReq, options: any = {}): RequestArgs {
      let version = 'v3'
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTemplates.'
        )
      }
      const localVarPath = `/multitransfer/multitransfer-fee-calc/{version}/commissions`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}

      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      const needsSerialization =
        typeof commssionReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'

      localVarRequestOptions.data = needsSerialization ? commssionReq : ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Создание перевода, обогащение данными отправителя
     * @param {'v1'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTransferCreate(clientReq, options: any = {}): RequestArgs {
      let version = 'v2'
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTemplates.'
        )
      }
      const localVarPath = `/multitransfer/multitransfer-transfer-create/{version}/transfers/create`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}

      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      const needsSerialization =
        typeof clientReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'

      localVarRequestOptions.data = needsSerialization ? clientReq : ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Сохранение досье клиента
     * @param {'v1'} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSaveClientData(clientReq, options: any = {}): RequestArgs {
      let version = 'v2'
      // verify required parameter 'version' is not null or undefined
      if (version === null || version === undefined) {
        throw new RequiredError(
          'version',
          'Required parameter version was null or undefined when calling getTemplates.'
        )
      }
      const localVarPath = `/multitransfer/multitransfer-client/{version}/clients/save`.replace(
        `{${'version'}}`,
        encodeURIComponent(String(version))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}

      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      const needsSerialization =
        typeof clientReq !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'

      localVarRequestOptions.data = needsSerialization ? clientReq : ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ClientInfoApi - functional programming interface
 * @export
 */
export const MultitransferApiAxios = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary получение списка стран для перевода за рубеж
     * @param {'v2'} version
     * @param {FullClientInfoReq} fullClientInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesList(
      version: 'v2',
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxiosResponse<CountriesResponse>> {
      const localVarAxiosArgs = MultitransferApiAxiosParamCreator(configuration).getCountriesList(version, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary получение списка стран и валют для перевода за рубеж
     * @param {'v2'} version
     * @param {FullClientInfoReq} fullClientInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currencyList(
      version: 'v2',
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxiosResponse<any>> {
      const localVarAxiosArgs = MultitransferApiAxiosParamCreator(configuration).getCurrencyCountries(version, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary поиск досье клиента в БД
     * @param {'v2'} version
     * @param {FullClientInfoReq} fullClientInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clientData(
      version: 'v2',
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxiosResponse<DataClientResponse>> {
      const localVarAxiosArgs = MultitransferApiAxiosParamCreator(configuration).getClientData(version, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary позволяет определить дополнительные параметры для совершения перевода
     * @param {'v2'} version
     * @param {FullClientInfoReq} fullClientInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    additionalFields(
      fieldsReq: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxiosResponse<AdditionalFieldsResponse>> {
      const localVarAxiosArgs = MultitransferApiAxiosParamCreator(configuration).getAdditionalFields(fieldsReq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary позволяет определить дополнительные параметры для совершения перевода
     * @param {'v2'} version
     * @param {FullClientInfoReq} fullClientInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commissionTransfer(
      commssionReq: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxiosResponse<any>> {
      const localVarAxiosArgs = MultitransferApiAxiosParamCreator(configuration).getCommissionTransfer(
        commssionReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },

    /**
     *
     * @summary позволяет определить дополнительные параметры для совершения перевода
     * @param {'v3'} version
     * @param {FullClientInfoReq} fullClientInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commissionV3Transfer(
      commssionReq: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxiosResponse<any>> {
      const localVarAxiosArgs = MultitransferApiAxiosParamCreator(configuration).getCommissionV3Transfer(
        commssionReq,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Создание перевода, обогащение данными отправителя
     * @param {'v2'} version
     * @param {FullClientInfoReq} fullClientInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTransfer(
      clientReq: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxiosResponse<TransferCreateResponse>> {
      const localVarAxiosArgs = MultitransferApiAxiosParamCreator(configuration).getTransferCreate(clientReq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @summary Сохранение досье клиента
     * @param {'v2'} version
     * @param {FullClientInfoReq} fullClientInfoReq
     * @param {string} [rboId]
     * @param {string} [authorization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveClientData(
      clientReq: any,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxiosResponse<DataClientSaveResponse>> {
      const localVarAxiosArgs = MultitransferApiAxiosParamCreator(configuration).getSaveClientData(clientReq, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class MultitransferApi extends BaseAPI {
  /**
   *
   * @summary Получение списка стран для перевода за рубеж
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */

  public getCountriesList(version: 'v2', options?: any) {
    return MultitransferApiAxios(this.configuration).countriesList(version, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Получение списка валют для перевода
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */

  public getCurrencyCountries(version: 'v2', options?: any) {
    return MultitransferApiAxios(this.configuration).currencyList(version, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary поиск досье клиента в БД
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */

  public getClientData(version: 'v2', options?: any) {
    return MultitransferApiAxios(this.configuration).clientData(version, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary позволяет определить дополнительные параметры для совершения перевода
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */

  public getAdditionalFields(fieldsReq, options?: any) {
    return MultitransferApiAxios(this.configuration).additionalFields(fieldsReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary позволяет определить дополнительные параметры для совершения перевода
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public getCommissionTransfer(commssionReq, options?: any) {
    return MultitransferApiAxios(this.configuration).commissionTransfer(commssionReq, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary позволяет определить дополнительные параметры для совершения перевода
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public getCommissionV3Transfer(commssionReq, options?: any) {
    return MultitransferApiAxios(this.configuration).commissionV3Transfer(commssionReq, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   *
   * @summary Создание перевода, обогащение данными отправителя
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */

  public getTransferCreate(clientReq, options?: any) {
    return MultitransferApiAxios(this.configuration).createTransfer(clientReq, options)(this.axios, this.basePath)
  }

  /**
   *
   * @summary Сохранение досье клиента
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */

  public getSaveClientData(clientReq, options?: any) {
    return MultitransferApiAxios(this.configuration).saveClientData(clientReq, options)(this.axios, this.basePath)
  }
}
