import { BANK_PRODUCT_DETAILS_LOAN, NCPK_PATH } from '@root/constants'
import { NewMainConfigs } from './types'

export const config: NewMainConfigs = {
  account: {
    add: {
      urlDetails: '/accountsopen?prod_code=MTSACC ',
      text: '{medium}Открыть счет{/medium} до\u00A021% годовых',
    },
  },
}

export const getConfigWithOffer = (hasOffer: boolean) => {
  return {
    urlDetails: hasOffer ? `${BANK_PRODUCT_DETAILS_LOAN}` : `${NCPK_PATH}?scroll=creditForm`,
    text: '{medium}Оформите заявку на\u00A0кредит{/medium} по\u00A0выгодным ставкам за\u00A05\u00A0минут',
  }
}
