import { ButtonProps } from '@mtsbank/ui-kit'
import { ImageData } from '@open-api/ump/communication-gateway'

export enum NotificationPermission {
  GRANTED = 'granted',
  DENIED = 'denied',
  DEFAULT = 'default',
}

export enum LocalStorageKey {
  WEB_PUSH_TOKEN = 'pushToken',
  POPUP_INIT_TIME = 'popupInitTime',
  POPUP_SHOW_TIME = 'popupShowTime',
  DEVICE_ID = 'deviceId',
}

export type PopupTime = LocalStorageKey.POPUP_INIT_TIME | LocalStorageKey.POPUP_SHOW_TIME

export interface WebPushToken {
  token: string
  expired: number
}

export type ModalConfig = Record<ModalType, ModalInfo>

export enum ModalType {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

interface Button {
  title: string
  variant: ButtonProps['variant']
}

export interface ModalInfo {
  title: string
  description: string
  img: {
    src: string
    size: ImageSize
  }
  buttons?: Record<'accept' | 'decline', Button>
}

export interface ImageSize {
  width: number
  height: number
}

export interface PopupManager {
  hasActions: () => boolean
  getPopupTime: (popupTime: PopupTime) => number
  setPopupTime: (popupTime: PopupTime, date: number) => void
  clearPopupTime: () => void
  isPopupAvailable: () => boolean
  addResetPopupListeners: () => void
  removeResetPopupListeners: () => void
  close?: () => void
}

export enum NotificationStatus {
  ERROR_ON_SENDING = 'error_on_sending', // Ошибка при отправке
  UNDELIVERED = 'undelivered', // Не доставлено
  DELIVERED = 'delivered', // Доставлено
}

export interface NotificationInfoProps extends Omit<NotificationOptions, 'data'> {
  data: {
    notificationId: string
    deepLink: string
  }
}

export interface NotificationEventInfo {
  status: NotificationEventStatus
}

export enum NotificationEventStatus {
  CLICKED = 'Clicked',
  READ = 'Read',
  READ_ON_HISTORY = 'ReadOnHistory',
}

export enum EventName {
  LOGOUT_EVENT = 'logout',
  STORAGE_EVENT = 'storage',
}

export enum LogEvent {
  NOTIFICATION_API_NOT_AVAILABLE = 'webPush_notificationApiNotAvailable',
  SW_API_NOT_AVAILABLE = 'webPush_swApiNotAvailable',
  PERMISSION_GRANTED = 'webPush_permissionGranted',
  REQUEST_PERMISSION = 'webPush_requestPermission',
  INIT_FCM = 'webPush_initFCM',
  GET_SW_REGISTRATION = 'webPush_getSWRegistration',
  SW_API_GET_REGISTRATION = 'webPush_swApiGetRegistration',
  SW_API_REGISTER = 'webPush_swApiRegister',
  SW_AWAIT_ACTIVATED = 'webPush_swAwaitActivated',
  SW_API_INSTALLING = 'webPush_swApiInstalling',
  SW_API_ACTIVE = 'webPush_swAPIActive',
  ON_MESSAGE = 'webPush_onMessage',
  UNAUTHORIZED = 'webPush_unauthorized',
  GET_FCM_PUSH_TOKEN = 'webPush_getFCMPushToken',
  ON_MESSAGE_FCM = 'webPush_onMessageFCM',
  SHOW_NOTIFICATION = 'webPush_showNotification',
  SW_API_SHOW_NOTIFICATION = 'webPush_swApiShowNotification',
  SW_API_INSTALLING_STATE_CHANGE = 'webPush_swApiInstallingStatechange',
  SW_API_ACTIVE_STATE_CHANGE = 'webPush_swApiActiveStatechange',
  IS_SW_READY = 'webPush_isSwReady',
  IS_PUSH_TOKEN_SENT = 'webPush_isPushTokenSent',
}

export enum LogApiEvent {
  SEND_PUSH_TOKEN = 'webPush_sendPushToken',
  GET_NOTIFICATION_TEXT = 'webPush_getNotificationText',
  SET_STATUS = 'webPush_setStatus',
}

/**
 *
 * @export
 * @interface GetNotificationTextRsp
 */
export interface GetNotificationTextRsp {
  /**
   *
   * @type {string}
   * @memberof GetNotificationTextRsp
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof GetNotificationTextRsp
   */
  text?: string
  /**
   *
   * @type {string}
   * @memberof GetNotificationTextRsp
   */
  link?: string
  /**
   *
   * @type {ImageData}
   * @memberof GetNotificationTextRsp
   */
  imageData?: ImageData
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof GetNotificationTextRsp
   */
  smartPush?: { [key: string]: string }
  /**
   *
   * @type {string}
   * @memberof GetNotificationTextRsp
   */
  type?: string
  /**
   *
   * @type {number}
   * @memberof GetNotificationTextRsp
   */
  templateId?: number
  /**
   *
   * @type {string}
   * @memberof GetNotificationTextRsp
   */
  deepLink?: string
}

/**
 * @export
 * @enum {string}
 */
export enum PushTokenTokenTypeEnum {
  DEBUG = 'payment_firebase_debug',
  RELEASE = 'payment_firebase_release',
}

/**
 *
 * @export
 * @interface SetUserNotificationStatusRsp
 */
export interface SetUserNotificationStatusRsp {
  /**
   *
   * @type {boolean}
   * @memberof SetUserNotificationStatusRsp
   */
  result?: boolean
}
