import { useCallback, useState, useEffect } from 'react'

// TODO remove after fix UIKIT-385. На iphone нет navigator.clipboard потому что по каким-то причинам window.isSecureContext = false
export const useClipboard = (duration = 2000) => {
  const [copied, setCopied] = useState(false)

  const copyText = useCallback(
    async (text: string) => {
      if (navigator.clipboard) {
        await navigator.clipboard
          .writeText(text)
          .then(() => setCopied(true))
          .catch(() => setCopied(false))
          .finally(() => {
            setTimeout(setCopied, duration, false)
          })
      } else {
        const el = document.createElement('textarea')

        el.value = text
        el.setAttribute('readonly', '')
        el.style.position = 'fixed'
        el.style.left = '-9999px'
        document.body.appendChild(el)
        el.select()

        const result = document.execCommand('copy')

        document.body.removeChild(el)
        setCopied(result)
        setTimeout(setCopied, duration, false)
      }
    },
    [duration]
  )

  return { copied, copyText }
}

export const useLongPress = (callback = () => {}, ms = 500) => {
  const [startLongPress, setStartLongPress] = useState(false)

  useEffect(() => {
    let timerId: number

    if (startLongPress) {
      timerId = window.setTimeout(callback, ms)
    } else {
      clearTimeout(timerId)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [callback, ms, startLongPress])

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false),
  }
}
