import process from 'process'
import Cookies from 'js-cookie'
import { ReactNode } from 'react'
import { uuid } from '@utils/generateUuid'
import { MobileAppWindow } from '@root/components/NCPK/types'

declare const window: MobileAppWindow

type TGroupIBInit = (values: {
  cid: string
  backUrl: string
  forceFirstAlive: boolean
  gafUrl?: string
  rsaModulus?: string
}) => void

type TGroupIB = {
  init: TGroupIBInit
  setAuthStatus: (values: boolean) => void
  setSessionID: (values: string) => void
  IS_GUEST?: boolean
  IS_AUTHORIZED?: boolean
  addInput?: (title: string, inputElement: ReactNode) => void
  setIdentityAndEncrypt?: (loginValue: string) => void
  setAttribute?: (name: string, value: number | string, options: { persistent: boolean }) => void
}

declare global {
  interface Window {
    gib: TGroupIB
    onGIBLoad?: TGroupIBInit
    gibSessionId?: string
    gibRequestId?: string
    gibUserId?: string
  }
}

interface TidData {
  tid: string
  timeStamp: Date
}

type SetIsGibInit = (status: boolean) => void

type GibInit = (setIsGibInit: SetIsGibInit) => void

export const GROUPIB_SCRIPT_URL = `${process.env.NEXT_PUBLIC_STATIC_HOST}/js/8242e180-450c-11ef-9f95-699eb4000000.js`
const rsaModulus =
  'B1A3EFBBF4A831C72485B0016269814CDBADF78E4ED11F3F0D79E488F212F2422BBB7930FB63749C3B33FD67ADB6223896078D969E81CEC5A1A09775D746A45B8C911E1DF14CFE27A4F6608F06833AAEBFC2C18CFC327243D78B2BD5853A6247A66BE2725C9484504D6EA311572283B5CF55E95E9C533858B23B770ABC9C38CE6F4575606B389858FFC1DF205D362D0BC786970BF7B77C7E5D0792A49BB93A6B9E60066F962B9F6013E1788607BA731EA7B3789C2D22B65162CB82DF7E5023187E1CB6FDFB35B47E548EAC6E3AE679D0F6DD1F60C016EE17B68E7CBFFE41E4D8F34DB129BDFF43910EE0BC217874DF7394455D405E1FB2B04308137810121635'
const TID_DATA = 'tidData'
const getTid = () => {
  const tidData: string = Cookies.get(TID_DATA)

  let parsedTidData: TidData

  if (tidData) {
    try {
      const decodedTidData = atob(tidData)

      parsedTidData = JSON.parse(decodedTidData)
    } catch (e) {
      parsedTidData = null
    }
  }

  if (parsedTidData) {
    const { tid, timeStamp } = parsedTidData
    const timeStumpNumber = new Date(timeStamp).getTime()

    const now = new Date().getTime()
    const fourHoursInMilliseconds = 4 * 60 * 60 * 1000

    if (now - timeStumpNumber < fourHoursInMilliseconds) {
      return tid
    }
  }

  const newTid = uuid()
  const newTimestamp = new Date().getTime()

  const newTidData = { tid: newTid, timeStamp: newTimestamp }

  const encodedTidData = btoa(JSON.stringify(newTidData))

  Cookies.set(TID_DATA, encodedTidData, {
    domain: window.location.hostname,
    expires: 1,
    path: '/',
    secure: window.location.protocol === 'https:',
    sameSite: 'Lax',
  })

  return newTid
}

export const removeTidCookie = () => {
  Cookies.remove(TID_DATA, {
    domain: window.location.hostname,
    expires: 1,
    path: '/',
    secure: window.location.protocol === 'https',
    sameSite: 'Lax',
  })
}

const handleLoadGib: GibInit = (setIsGibInit) => {
  window.gib.init({
    cid: process.env.NEXT_PUBLIC_BUILD_ENV === 'production' ? 'mts-w-payment' : 'mts-w-payment-t',
    backUrl: '//sb.mts.ru/api/fl',
    forceFirstAlive: true,
    gafUrl: '//ru.id.group-ib.com/id.html',
    rsaModulus,
  })
  window.gibSessionId = getTid()
  window.gib.setSessionID(window.gibSessionId)
  setIsGibInit(true)
}

export const gibInit: GibInit = (setIsGibInit) => {
  if (typeof window !== 'undefined' && window.gib) {
    handleLoadGib(setIsGibInit)
  } else {
    window.onGIBLoad = () => handleLoadGib(setIsGibInit)
  }
}

export const isNotWebView = () => {
  const isAndroidSDK = window.AndroidSdk
  const isIosSDK = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.request_iOS_SDK

  return !isAndroidSDK && !isIosSDK
}

class GroupIBService {
  static addElement(elem: HTMLInputElement) {
    window.gib?.addInput(elem && elem.name, elem)
  }

  static setAttribute(issueId?: number) {
    const value = typeof issueId !== 'undefined' ? `Payment-${issueId}` : null

    window.gib?.setAttribute('Request_id', value, { persistent: true })
  }
}

export { GroupIBService }
