export interface ReportEventOptions {
  duration?: number
  timestamp?: number
  backendTraceId?: string
  error?: Error
  componentStack?: string
  meta?: Meta
}

export interface ReportErrorOptions {
  componentStack?: string
  meta?: Meta
}

interface Meta {
  [key: string]: string | number | boolean
}

export enum CommandType {
  REPORT_ERROR = 'reportError',
  REPORT_EVENT = 'reportEvent',
}
