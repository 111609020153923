import { window } from '@mtsbank/ui-kit'
import { EventName, LocalStorageKey, PopupManager, PopupTime } from '@utils/webPush/types'
import { webPush } from '@utils/webPush/webPush'

/**
 * Время, через которое появляется попап с предложением подключить webPush уведомления
 */
export const DELAY_TIME = 30000 // 30 seconds in ms

/**
 * Установка флага инициализаци/показа попапа пользовтелю с предложением подключить webPush-уведомления
 * (popupInitTime: unixTimeStamp, popupShowTime: unixTimeStamp)
 * Значение {unixTimeStamp} не имеет значения, важно наличие или отсутствия ключа в LS
 *
 * флаг необходим для определения показывать пользователю попап или нет
 * если флаг отсутствует, то нужно показать попап, если установлен - показ не требуется
 *
 * @param popupTime
 * @param date
 */
const setPopupTime = (popupTime: PopupTime, date: number) => {
  window.localStorage.setItem(popupTime, `${date}`)
}

/**
 * Получение значение ключа popupInitTime/popupShowTime
 * Значение ключа(unixTimeStamp) не имеет значения, важно наличие или отсутствия ключа в LS
 *
 * @param popupTime
 */
const getPopupTime = (popupTime: PopupTime) => {
  try {
    return Number(window.localStorage.getItem(popupTime))
  } catch (error) {
    return null
  }
}

/**
 * Удаление ключа popupShowTime
 */
const clearPopupTime = () => {
  window.localStorage.removeItem(LocalStorageKey.POPUP_SHOW_TIME)
}

/**
 * Удаление флага показа попапа при возникновении event-а "разлогин"
 *
 * @param event
 */
const handleLogoutEvent = (event: StorageEvent | Event) => {
  if (
    (event instanceof StorageEvent && event.key === 'access_token') ||
    (event instanceof Event && event.type === EventName.LOGOUT_EVENT)
  ) {
    clearPopupTime()
  }
}
/**
 * Определяет, нужно ли пользователю показать попап с предложением подключить webPush-уведомления
 *
 *  @returns {boolean}
 */
const isPopupAvailable = () =>
  !webPush.isBlocked() && !webPush.isPermissionGranted() && !getPopupTime(LocalStorageKey.POPUP_SHOW_TIME)

/**
 * Определяет вид попапа - с кнопками "Позже" и "Хорошо" (отклонить или разрешить) или без них
 *
 * для Safari и Firefox и мобильном разрешении требуется отображать попап с кнопками,
 * во всех других случаях - без кнопок, но с иконкой "close" - закрытие попапа
 *
 * @returns {boolean}
 */
const hasActions = () => {
  const userAgent = window.navigator?.userAgent
  const isSafari = Boolean(userAgent.includes('Safari') && !userAgent.includes('Chrome'))
  const isFirefox = Boolean(userAgent.includes('Firefox'))
  const isYaBrowser = Boolean(userAgent.includes('YaBrowser'))

  return isSafari || isFirefox || isYaBrowser
}

/**
 * Добавление listeners, при которых будет удаляться информация о том, что попап был показан
 *
 * требуется удалять информацию о показе попапа при разлогине (кнопка "Выйти" или протухание токена)
 */
const addResetPopupListeners = () => {
  window.addEventListener(EventName.STORAGE_EVENT, handleLogoutEvent)
  window.addEventListener(EventName.LOGOUT_EVENT, handleLogoutEvent)
}

/**
 * Удаление listeners, при которых будет удаляться информация о том, что попап был показан
 *
 * требуется удалять информацию о показе попапа при разлогине (кнопка "Выйти" или протухание токена)
 */
const removeResetPopupListeners = () => {
  window.removeEventListener(EventName.STORAGE_EVENT, handleLogoutEvent)
  window.removeEventListener(EventName.LOGOUT_EVENT, handleLogoutEvent)
}

export const popupManager: PopupManager = {
  hasActions,
  getPopupTime,
  setPopupTime,
  clearPopupTime,
  isPopupAvailable,
  addResetPopupListeners,
  removeResetPopupListeners,
}
