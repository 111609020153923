import { FirebaseApp, initializeApp } from 'firebase/app'
import { getMessaging, getToken as getFCMToken, Messaging, onMessage as onMessageFCM } from 'firebase/messaging'
import { LocalStorageKey, LogApiEvent, LogEvent, NotificationPermission, WebPushToken } from '@utils/webPush/types'
import { firebaseAppConfig } from '@utils/webPush/firebaseAppConfig'
import { SERVICE_WORKER, SERVICE_WORKER_SCOPE, VAPID_KEY } from '@utils/webPush/constants'
import { popupManager } from '@utils/webPush/popupManager'
import { NotificationInfo } from '@utils/webPush/NotificationInfo'
import { ApiRequest } from '@utils/webPush/ApiRequest'
import { logError, logEvent } from '@utils/instana/instana'
import { webPushSendGtm } from '@utils/gtm/webPush/events'
import { EventLabel, DataLayerEvents } from '@utils/gtm/webPush/types'

/**
 * Время, после истечения которого, запрашивается новый pushToken из firebase, если имеется сохранённый pushToken в LS
 */
const PUSH_TOKEN_EXPIRATION_TIME = 1296000000 // 15 days in ms

let app: FirebaseApp
let messaging: Messaging

/**
 * Проверка доступности NotificationApi у пользовтаеля
 *
 * @return {boolean}
 */
const isNotificationApiAvailable = () => {
  const isNotificationApiAvailable = 'Notification' in window

  if (!isNotificationApiAvailable) {
    logEvent(LogEvent.NOTIFICATION_API_NOT_AVAILABLE, {
      meta: {
        title: 'NotificationApi не доступен',
      },
    })
  }

  return isNotificationApiAvailable
}

/**
 * Проверка доступности api serviceWorker  у пользователя
 *
 * @return {boolean}
 */
const isServiceWorkerApiAvailable = () => {
  const isServiceWorkerApiAvailable = 'serviceWorker' in navigator

  if (!isServiceWorkerApiAvailable) {
    logEvent(LogEvent.SW_API_NOT_AVAILABLE, {
      meta: {
        title: 'ServiceWorkerApi не доступен',
      },
    })
  }

  return isServiceWorkerApiAvailable
}

/**
 * Проверка доступности webPush у пользователя
 * Если один из API не доступен, то никаких действий с пушами пользователю не предлагается
 *
 * @return {boolean}
 */
const isWebPushAvailable = () => isNotificationApiAvailable() && isServiceWorkerApiAvailable()

/**
 * Проверяет разрешил ли пользователь отправлять ему уведомления
 * (Системное окно о разрешение на отправку уведомлений, пользователь может разрешитьл/запретить/закрыть окно)
 *
 * @param permission
 * @return {boolean}
 */
const isPermissionGranted = (permission?: typeof Notification.permission) =>
  isNotificationApiAvailable() &&
  (permission === NotificationPermission.GRANTED || Notification.permission === NotificationPermission.GRANTED)

/**
 * Проверяет, отображать ли пользователю системное окно с запросом на отправку уведомлений
 * (если окно уже показывалось пользователю и было закрыто без выбора разрешить/запретить )
 *
 * @return {boolean}
 */
const isNeedRequestPermission = () =>
  isNotificationApiAvailable() &&
  !(
    Notification.permission === NotificationPermission.GRANTED ||
    Notification.permission === NotificationPermission.DENIED
  )

/**
 * Проверяет, запретил ли пользователь отправку уведомлений
 * (пользовтаель нажал "запретить" на запрос об отправке увдеомлений)
 *
 * @return {boolean}
 */
const isBlocked = () => isNotificationApiAvailable() && Notification.permission === NotificationPermission.DENIED

/**
 * Инициализация веб-пушей у пользователя
 * если доступны все API и пользователем дано разрешение на получение уведомлений,
 * осуществялется подписка на получение уведомлений
 */
const init = () => {
  if (!isWebPushAvailable()) {
    return
  }

  if (isPermissionGranted()) {
    logEvent(LogEvent.PERMISSION_GRANTED, {
      meta: {
        title: 'Разрешена отправка уведомлений',
        description: 'Осуществляется подписка на пуши: subscribe()',
      },
    })

    subscribe()
  }

  if (isNeedRequestPermission()) {
    requestPermission().then((permission) => {
      if (permission === NotificationPermission.DENIED) {
        webPushSendGtm(DataLayerEvents.CLICK_BLOKIROVAT_SISTEMNOE_OKNO, {
          eventLabel: EventLabel.BLOKIROVAT,
        })
      }

      logEvent(LogEvent.REQUEST_PERMISSION, {
        meta: {
          title: 'Запрос разрешения на отправку уведомлений',
          details: JSON.stringify({
            permission: `${permission}`,
          }),
        },
      })
      const showTime = popupManager.getPopupTime(LocalStorageKey.POPUP_SHOW_TIME)

      if (!showTime) {
        popupManager.close()
      }

      if (isPermissionGranted(permission)) {
        webPushSendGtm(DataLayerEvents.CLICK_RAZRESHIT_SISTEMNOE_OKNO, {
          eventLabel: EventLabel.RAZRESHIT,
        })

        logEvent(LogEvent.PERMISSION_GRANTED, {
          meta: {
            title: 'Разрешена отправка уведомлений',
            description:
              'Осуществялется подписка на пуши (isNeedRequestPermission() -> requestPermission() -> isPermissionGranted(permission) -> subscription())',
          },
        })

        subscribe()
      }
    })
  }
}

/**
 * Получение сохранённого в LS pushToken из firebase
 * {token} - сам токен
 * {expired} - дата (unixTimestamp), после истечения которой необходимо запросить новый токен из firebase
 * {refresh} - дата (unixTimestamp), после истечения которой необходимо отправить {token} в ComDomain (/push_token/web),
 * чтобы продолжать получать уведомления
 *
 */
const getToken = () => {
  try {
    const pushTokenData = window.localStorage.getItem(LocalStorageKey.WEB_PUSH_TOKEN)

    const { token, expired } = JSON.parse(pushTokenData) as WebPushToken

    if (Number(expired) < Date.now()) {
      removeToken()

      return null
    }

    return { token, expired }
  } catch (error) {
    return null
  }
}

/**
 * Сохранение pushToken, полученного из firebase в LS, чтобы каждый раз не запрашивать новый
 * Запрос нового токена происходит по истечению времени {PUSH_TOKEN_EXPIRATION_TIME}
 *
 * @param token
 */
const saveToken = (token: string) => {
  const currentTime = Date.now()
  const pushTokenData: WebPushToken = {
    token,
    expired: currentTime + PUSH_TOKEN_EXPIRATION_TIME,
  }

  window.localStorage.setItem(LocalStorageKey.WEB_PUSH_TOKEN, JSON.stringify(pushTokenData))
}

/**
 * Удаление pushToken из LS
 */
const removeToken = () => {
  window.localStorage.removeItem(LocalStorageKey.WEB_PUSH_TOKEN)
}

/**
 * Ожидает, когда serviceWorker будет готов обрабатывать события (поулчать пуши с firebase)
 * (state="activated" The service worker in this state is considered an active worker ready to handle functional events.)
 *
 * @param serviceWorkerRegistration
 */
const awaitServiceWorkerActivated = (serviceWorkerRegistration: ServiceWorkerRegistration) => {
  logEvent(LogEvent.SW_AWAIT_ACTIVATED, {
    meta: {
      title: 'sw зарегистрирован, ожидание статуса sw (state="activated") для запроса pushToken из FCM',
      description: 'awaitServiceWorkerActivated()',
    },
  })

  return new Promise<ServiceWorkerRegistration>((resolve) => {
    if (serviceWorkerRegistration.installing) {
      logEvent(LogEvent.SW_API_INSTALLING, {
        meta: {
          title: 'sw устанавливается',
          description: 'состояние sw: serviceWorkerRegistration.installing',
        },
      })

      serviceWorkerRegistration.installing.addEventListener('statechange', (event) => {
        const { state } = event.target as ServiceWorker

        logEvent(LogEvent.SW_API_INSTALLING_STATE_CHANGE, {
          meta: {
            title: 'Подписка на событие statechange',
            description: 'serviceWorkerRegistration.installing.addEventListener("statechange",  (event) => {})',
            details: JSON.stringify({
              state,
            }),
          },
        })

        if (state === 'activated') {
          logEvent(LogEvent.SW_API_INSTALLING_STATE_CHANGE, {
            meta: {
              title: 'Подписка на событие statechange',
              description: 'state = "activated", resolve(serviceWorkerRegistration)',
              details: JSON.stringify({
                state,
              }),
            },
          })

          return resolve(serviceWorkerRegistration)
        }
      })
    }

    if (serviceWorkerRegistration.active) {
      logEvent(LogEvent.SW_API_ACTIVE, {
        meta: {
          title: 'sw активен',
          description: 'состояние sw: serviceWorkerRegistration.active',
        },
      })

      serviceWorkerRegistration.active.addEventListener('statechange', (event) => {
        const { state } = event.target as ServiceWorker

        logEvent(LogEvent.SW_API_ACTIVE_STATE_CHANGE, {
          meta: {
            title: 'Подписка на событие statechange',
            description: 'serviceWorkerRegistration.active.addEventListener("statechange",  (event) => {})',
            details: JSON.stringify({
              state,
            }),
          },
        })

        if (state === 'activated') {
          logEvent(LogEvent.SW_API_INSTALLING_STATE_CHANGE, {
            meta: {
              title: 'Подписка на событие statechange',
              description: 'state = "activated", resolve(serviceWorkerRegistration)',
              details: JSON.stringify({
                state,
              }),
            },
          })

          return resolve(serviceWorkerRegistration)
        }
      })
    }
  })
}

/**
 * Возвращает serviceWorkerRegistration
 * если serviceWorkerRegistration отсутсвует (serviceWorker не зарегистрирован), то происходит регистрация serviceWorker
 *
 * @return {Promise<ServiceWorkerRegistration>}
 */
const getServiceWorkerRegistration = () => {
  logEvent(LogEvent.GET_SW_REGISTRATION, {
    meta: {
      title: 'Получение инстанса serviceWorkerRegistration',
      description: 'getServiceWorkerRegistration()',
    },
  })

  return navigator.serviceWorker
    .getRegistration(SERVICE_WORKER_SCOPE)
    .then((serviceWorkerRegistration) => {
      if (!serviceWorkerRegistration) {
        logEvent(LogEvent.SW_API_GET_REGISTRATION, {
          meta: {
            title: 'sw не зарегистрирован',
            description: ' navigator.serviceWorker.getRegistration(SERVICE_WORKER_SCOPE)',
            details: JSON.stringify({
              SERVICE_WORKER_SCOPE,
            }),
          },
        })
      }

      return (
        serviceWorkerRegistration ||
        navigator.serviceWorker
          .register(SERVICE_WORKER, { scope: SERVICE_WORKER_SCOPE })
          .then((serviceWorkerRegistration) => {
            logEvent(LogEvent.SW_API_REGISTER, {
              meta: {
                title: 'Регистрация sw',
                description: 'navigator.serviceWorker.register(SERVICE_WORKER, { scope: SERVICE_WORKER_SCOPE })',
                details: JSON.stringify({
                  SERVICE_WORKER,
                  scope: SERVICE_WORKER_SCOPE,
                }),
              },
            })

            return awaitServiceWorkerActivated(serviceWorkerRegistration)
          })
          .catch((error) => {
            logEvent(`${LogEvent.SW_API_REGISTER}_error `, {
              meta: {
                title: 'Ошибка при регистрции sw',
                description: 'navigator.serviceWorker.register(SERVICE_WORKER, { scope: SERVICE_WORKER_SCOPE })',
                details: JSON.stringify({
                  SERVICE_WORKER,
                  scope: SERVICE_WORKER_SCOPE,
                  error: error.stack,
                }),
              },
            })

            throw error
          })
      )
    })
    .catch((error) => {
      logEvent(LogEvent.GET_SW_REGISTRATION, {
        meta: {
          title: 'Ошибка при получнеии sw',
          description: 'navigator.serviceWorker.getRegistration(SERVICE_WORKER_SCOPE)',
          details: JSON.stringify({
            SERVICE_WORKER_SCOPE,
            error: error.stack,
          }),
        },
      })

      throw error
    })
}

/**
 * Проверяет наличие pushToken в LS, если токен есть, то значит ранее он был передан в ComDomain и sw готов к получению уведомлений
 * если токен отсутсвует осуществляется запрос к FCM, после чего токен отправляется в ComDomain
 * sw готов к приёму уведомлений только после успешной отправке токена в ComDomain
 *
 * @param notificationInfo
 *
 * @return {Promise<ServiceWorkerRegistration>}
 */
const isPushTokenSent = (notificationInfo: NotificationInfo) => {
  logEvent(LogEvent.IS_PUSH_TOKEN_SENT, {
    meta: {
      title: 'Осуществляется проверка, отправлен ли pushToken из FCM в ComDomain',
      description: 'isPushTokenActive()',
    },
  })

  const tokenInfo = getToken()

  if (tokenInfo?.token) {
    logEvent(LogEvent.IS_PUSH_TOKEN_SENT, {
      meta: {
        title: 'pushToken есть LS ',
        description: 'resolve(true)',
        details: JSON.stringify({
          token: tokenInfo.token,
        }),
      },
    })

    return Promise.resolve(true)
  }

  return new Promise<boolean>((resolve, reject) => {
    getPushToken(notificationInfo, messaging)
      .then((token) =>
        notificationInfo
          .sendPushToken(token)
          .then(({ result }) => {
            logEvent(LogApiEvent.SEND_PUSH_TOKEN, {
              meta: {
                title: 'comDomainApi: sendPushToken(token), отправка полученного pushToken из FCM',
                description: 'resolve(serviceWorkerRegistration)',
                details: JSON.stringify({
                  token,
                }),
              },
            })

            if (result === true) {
              saveToken(token)

              return resolve(true)
            }

            return reject(new Error('Push token not sent'))
          })
          .catch((error) => {
            logEvent(`${LogApiEvent.SEND_PUSH_TOKEN}_error`, {
              meta: {
                title: 'comDomainApi: sendPushToken(token), ошибка при отправке полученного pushToken из FCM',
                details: JSON.stringify({
                  token,
                  error: error.stack,
                }),
              },
            })

            return reject(error)
          })
      )
      .catch((error) => {
        logEvent(`${LogEvent.GET_FCM_PUSH_TOKEN}_error`, {
          meta: {
            title: 'Ошибка при получение pushToken из FCM',
            details: JSON.stringify({
              error: error.stack,
            }),
          },
        })

        return reject(error)
      })
  })
}

/**
 * Подписка на пуш-уведомления
 * с firebase приходит уведомление - {notificationId}, которое отправляет ComDomain
 * далее по {notificationId} запрашивается информация полная информарция об уведомлении у ComDomain и пользователю отображается пуш-уведомление
 *
 * @param notificationInfo
 * @param messaging
 */
const onMessage = (notificationInfo: NotificationInfo, messaging: Messaging) => {
  logEvent(LogEvent.ON_MESSAGE, {
    meta: {
      title: 'Подготовка к подписке на уведомления от FCM',
      description: 'onMessage()',
    },
  })

  isPushTokenSent(notificationInfo)
    .then(() => {
      onMessageFCM(messaging, ({ data: { notificationId } }) => {
        logEvent(LogEvent.ON_MESSAGE_FCM, {
          meta: {
            title: 'Подписка на пуши FCM',
            details: JSON.stringify({
              messaging,
              notificationId,
            }),
          },
        })
        notificationInfo.showNotification(notificationId)
      })
    })
    .catch((error) => {
      logEvent(`${LogEvent.IS_PUSH_TOKEN_SENT}_error`, {
        meta: {
          title: 'Ошибка: pushToken не отрпавлен в ComDomain, подписка на уведомления не осущетсвлена',
          details: JSON.stringify({
            error: error.stack,
          }),
        },
      })
    })
}

/**
 * Подписка на пуш-уведомления
 */
const subscribe = () => {
  app = initializeApp(firebaseAppConfig)
  messaging = getMessaging(app)

  logEvent(LogEvent.INIT_FCM, {
    meta: {
      title: 'Инициализация FCM',
    },
  })

  getServiceWorkerRegistration()
    .then((serviceWorkerRegistration) => {
      const apiRequest = new ApiRequest()
      const notificationInfo = new NotificationInfo(serviceWorkerRegistration, apiRequest)

      logEvent(LogEvent.GET_SW_REGISTRATION, {
        meta: {
          title: 'Получение serviceWorkerRegistration',
          description: 'getServiceWorkerRegistration()',
        },
      })

      onMessage(notificationInfo, messaging)
    })
    .catch((error) => {
      logEvent(`${LogEvent.GET_SW_REGISTRATION}_error`, {
        meta: {
          title: 'sw не зарегистрирован',
          details: JSON.stringify({
            error: error.stack,
          }),
        },
      })
    })
}

/**
 * Запрос на отправку пуш-уведомлений пользователю
 * (пользователю показывается системное окно, где ему необходиом разрешить/запретить отправку уведомлений)
 */
const requestPermission = () =>
  Notification.requestPermission()
    .then((permission) => permission)
    .catch((error) => {
      logEvent(LogEvent.REQUEST_PERMISSION, {
        meta: {
          title: 'Ошибка при запросе на отправку пуш-уведомлений',
          details: JSON.stringify({
            error: error.stack,
          }),
        },
      })

      return Notification.permission
    })

/**
 * Запрашивает pushToken из firebase
 *
 * @param notificationInfo
 * @param messaging
 */
const getPushToken = (notificationInfo: NotificationInfo, messaging: Messaging): Promise<string> =>
  getFCMToken(messaging, {
    vapidKey: VAPID_KEY,
    serviceWorkerRegistration: notificationInfo.getServiceWorkerRegistration(),
  }).then((currentToken) => {
    logEvent(LogEvent.GET_FCM_PUSH_TOKEN, {
      meta: {
        title: 'Полечение pushToken из FCM',
        description: 'getFCMToken(messaging, {vapidKey: VAPID_KEY,serviceWorkerRegistration,})',
        details: JSON.stringify({
          VAPID_KEY,
        }),
      },
    })

    return currentToken
  })

/**
 * Отписка от пуш-уведомлений
 * (происходит, если пользователья нажал кнопку "выйти")
 */
const unsubscribe = () => {
  getServiceWorkerRegistration()
    .then((serviceWorkerRegistration: ServiceWorkerRegistration) => serviceWorkerRegistration.unregister())
    .then(removeToken)
    .catch((error) => {
      logError(error as Error)
    })
}

export const webPush = { init, isPermissionGranted, isBlocked, unsubscribe }
