import { ConfigParamsByEvent, EventContent, DataLayerEvents } from './types'

/**
 * todo при иморте actionGroups и eventActions из baseFunctions во время билда возникает ошибка
 *  ReferenceError: Cannot access 'actionGroups' before initialization, поэтому объявлены в этом же файлике
 *  ошибка возникает, если вызов webPushSendGtm осуществляется в .ts - файле
 *  если вызов webPushSendGtm осущетсвляется в .tsx-файле билд проходит без ошибки
 *  webPushSendGtm (./events.ts) исопльзует configParamsByEvent
 *  нужно разобраться/поэксперементировать с билодом (настройками webpack)
 */

const eventActions = {
  elementShow: 'element_show',
  buttonClick: 'button_click',
}

const actionGroups = {
  nonInteractions: 'non_interactions',
  interactions: 'interactions',
}

export const configParamsByEvent: ConfigParamsByEvent = {
  [DataLayerEvents.SHOW_POPUP]: {
    eventAction: eventActions.elementShow,
    actionGroup: actionGroups.nonInteractions,
  },
  [DataLayerEvents.CLICK_RAZRESHIT_ISPOLZOVANIE_PUSHEI_POPUP]: {
    eventAction: eventActions.buttonClick,
    actionGroup: actionGroups.interactions,
    eventContent: EventContent.POPUP,
  },
  [DataLayerEvents.CLICK_NE_RAZRESHAT_ISPOLZOVANIE_PUSHEI_POPUP]: {
    eventAction: eventActions.buttonClick,
    actionGroup: actionGroups.interactions,
    eventContent: EventContent.POPUP,
  },
  [DataLayerEvents.CLICK_BLOKIROVAT_SISTEMNOE_OKNO]: {
    eventAction: eventActions.buttonClick,
    actionGroup: actionGroups.interactions,
    eventContent: EventContent.SISTEMNOE_OKNO,
  },
  [DataLayerEvents.CLICK_RAZRESHIT_SISTEMNOE_OKNO]: {
    eventAction: eventActions.buttonClick,
    actionGroup: actionGroups.interactions,
    eventContent: EventContent.SISTEMNOE_OKNO,
  },
  [DataLayerEvents.SHOW_PUSH]: {
    eventAction: eventActions.elementShow,
    actionGroup: actionGroups.nonInteractions,
  },
  [DataLayerEvents.CLICK_PUSH]: {
    eventAction: eventActions.elementShow,
    actionGroup: actionGroups.nonInteractions,
  },
}
