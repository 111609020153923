import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FieldProps } from 'formik'

import debounce from 'lodash.debounce'
import { InputHelperText, TextField as KitTextField, TextFieldProps as KitTextFieldProps } from '@mtsbank/ui-kit'

import { InputErrorMessage } from '@components/InputErrorMessage/InputErrorMessage'

export interface TextFieldProps extends FieldProps, KitTextFieldProps {
  hint: string
  shouldTouchOnChange?: boolean
  shouldTouchOnBlur?: boolean
  className?: string
  limit?: number
  onBlur?: (event?) => void
  onClear?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const TextField: FC<TextFieldProps> = ({
  field,
  form: { touched, errors, setFieldTouched, setFieldValue },
  onChange,
  hint,
  shouldTouchOnChange = true,
  shouldTouchOnBlur = false,
  className,
  onClear,
  onBlur,
  ...rest
}) => {
  const touchedItem = touched[field.name]
  const errorItem = errors[field.name]
  const [hasError, setHasError] = useState<boolean>(touchedItem && Boolean(errorItem))

  const setHasErrorDebounced = useMemo(
    () =>
      debounce((hasError: boolean) => setHasError(hasError), 100, {
        leading: false,
        trailing: true,
      }),
    [setHasError]
  )
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event)
      } else {
        setFieldValue(field.name, event.target.value)
      }

      if (shouldTouchOnChange) {
        setFieldTouched(field.name, true, false)
      }
    },
    [field.name, onChange, setFieldTouched, setFieldValue, shouldTouchOnChange]
  )

  const handleClear = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (onClear) {
        onClear(event)
      } else {
        setFieldValue(field.name, '')
      }
    },
    [field.name, onClear, setFieldValue]
  )

  const handleBlur = useCallback(() => {
    if (onBlur) {
      onBlur()
    } else if (shouldTouchOnBlur) {
      setFieldTouched(field.name, true, false)
    }
  }, [field.name, setFieldTouched, shouldTouchOnBlur])

  useEffect(() => {
    setHasErrorDebounced(touchedItem && Boolean(errorItem))
  }, [touchedItem, errorItem, setHasErrorDebounced])

  return (
    <div className={className}>
      <KitTextField
        {...field}
        {...rest}
        data-testid={field.name}
        hasError={hasError}
        onClear={handleClear}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hint && !hasError && <InputHelperText>{hint}</InputHelperText>}
      {hasError && <InputErrorMessage name={field.name} />}
    </div>
  )
}
