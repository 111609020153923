import { DefaultKeys, EDataLayerEvents, EPrefillFieldName, EPrefillFieldNameFA } from './types'

export const DEFAULT_CITY_NAME = 'Москва'

export const MTS_PAGE_VIEW_EVENT = 'mtsPageview'

export const stepTwoPrefill = [EPrefillFieldName.FIO, EPrefillFieldName.BIRTHDAY, EPrefillFieldName.EMAIL]

export const stepThreePrefill = [
  EPrefillFieldName.GENDER,
  EPrefillFieldName.PASSPORT,
  EPrefillFieldName.DATA_ISSUE,
  EPrefillFieldName.CODE_DEP,
  EPrefillFieldName.DEP,
  EPrefillFieldName.REG_ADDRESS,
  EPrefillFieldName.ADDRESS_MATCH,
  EPrefillFieldName.FACT_ADDRESS,
  EPrefillFieldName.USA_TAX,
  EPrefillFieldName.FOREIGN_RES,
]

export const stepFourPrefill = [
  EPrefillFieldName.ORG_NAME,
  EPrefillFieldName.NAME_POSITIOON,
  EPrefillFieldName.ADDRESS_ORG,
  EPrefillFieldName.ORG_PHONE,
  EPrefillFieldName.MART_STATUS,
  EPrefillFieldName.DEPENDETS,
]

export const personalDataPrefillFA = [
  EPrefillFieldNameFA.PERSON,
  EPrefillFieldNameFA.BIRTHDAY,
  EPrefillFieldNameFA.EMAIL,
  EPrefillFieldNameFA.PHONE,
]

export const passportDataPrefillFA = [
  EPrefillFieldNameFA.PASSPORT,
  EPrefillFieldNameFA.DATA_ISSUE,
  EPrefillFieldNameFA.CODE_DEP,
  EPrefillFieldNameFA.DEP,
  EPrefillFieldNameFA.REG_ADDRESS,
  EPrefillFieldNameFA.FACT_ADDRESS,
]

export const organizationDataPrefillFA = [
  EPrefillFieldNameFA.WORK_ADDRESS,
  EPrefillFieldNameFA.WORK_INN,
  EPrefillFieldNameFA.WORK_NAME,
  EPrefillFieldNameFA.WORK_PHONE,
  EPrefillFieldNameFA.WORK_POSITION,
  EPrefillFieldNameFA.MARTIAL_STATUS,
  EPrefillFieldNameFA.DEPENDENTS,
]

export const organizationIndoPrefillFA = [
  EPrefillFieldNameFA.WORK_ADDRESS,
  EPrefillFieldNameFA.WORK_INN,
  EPrefillFieldNameFA.WORK_NAME,
]

export const NAME_TO_ANALYTICS_LABEL = {
  [EPrefillFieldName.BIRTHDAY]: 'data_rozhdeniya',
  [EPrefillFieldName.FIO]: 'familiya_imya_otchetsvo',
  [EPrefillFieldName.EMAIL]: 'email',
  [EPrefillFieldName.GENDER]: 'pol',
  [EPrefillFieldName.PASSPORT]: 'seriya_i_nomer_pasporta',
  [EPrefillFieldName.DATA_ISSUE]: 'data_vydachi_pasporta',
  [EPrefillFieldName.CODE_DEP]: 'kod_podrazdeleniya',
  [EPrefillFieldName.DEP]: 'kem_vydan',
  [EPrefillFieldName.REG_ADDRESS]: 'adres_registracii',
  [EPrefillFieldName.ADDRESS_MATCH]: 'adres_prozhivaniya_sovpadaet_s_adresom_registracii',
  [EPrefillFieldName.FACT_ADDRESS]: 'adres_fakticheskogo_prozhivaniya',
  [EPrefillFieldName.USA_TAX]: 'ya_nalogoplatelschik_ssha',
  [EPrefillFieldName.FOREIGN_RES]: 'resident',
  [EPrefillFieldName.ORG_NAME]: 'nazvanie_organizacii',
  [EPrefillFieldName.NAME_POSITIOON]: 'polnoe_nazvanie_dolzhnosti',
  [EPrefillFieldName.ADDRESS_ORG]: 'adres_organizacii',
  [EPrefillFieldName.ORG_PHONE]: 'rabochii_telefon',
  [EPrefillFieldName.MART_STATUS]: 'semeinoe_polozhenie',
  [EPrefillFieldName.DEPENDETS]: 'kolichestvo_chelovek_na_izhdivenii',
  [EPrefillFieldNameFA.PERSON]: 'familiya_imya_otchetsvo',
  [EPrefillFieldNameFA.BIRTHDAY]: 'data_rozhdeniya',
  [EPrefillFieldNameFA.EMAIL]: 'email',
  [EPrefillFieldNameFA.PHONE]: 'mobilnyi_telefon',
  [EPrefillFieldNameFA.PASSPORT]: 'seriya_i_nomer_pasporta',
  [EPrefillFieldNameFA.DATA_ISSUE]: 'data_vydachi_pasporta',
  [EPrefillFieldNameFA.CODE_DEP]: 'kod_podrazdeleniya',
  [EPrefillFieldNameFA.DEP]: 'kem_vydan',
  [EPrefillFieldNameFA.REG_ADDRESS]: 'adres_registracii',
  [EPrefillFieldNameFA.FACT_ADDRESS]: 'adres_prozhivaniya',
  [EPrefillFieldNameFA.WORK_ADDRESS]: 'adres_organizacii',
  [EPrefillFieldNameFA.WORK_INN]: 'inn_organizacii',
  [EPrefillFieldNameFA.WORK_NAME]: 'nazvanie_organizacii',
  [EPrefillFieldNameFA.WORK_PHONE]: 'rabochii_telefon',
  [EPrefillFieldNameFA.WORK_POSITION]: 'polnoe_nazvanie_dolzhnosti',
  [EPrefillFieldNameFA.MARTIAL_STATUS]: 'semeinoe_polozhenie',
  [EPrefillFieldNameFA.DEPENDENTS]: 'kolichestvo_chelovek_na_izhdivenii',
}

export const emptyDefault: Record<DefaultKeys, string> = {
  event: '',
  eventCategory: '',
  productId: '',
  productName: null,
}

export const EVENT_CONTENT = {
  STEP_1: 'step_1',
  STEP_2: 'step_2',
  STEP_3: 'step_3',
  STEP_4: 'step_4',
  STEP_5: 'step_5',
  SLIDER: 'polzunok',
  HANDLE: 'vruchnuu',
  BIRTHDAY: 'data_rozhdeniya',
  GOAL: 'cel_kredita',
  LEVEL: 'uroven_dolzhnosti',
  EDUCATION: 'obrazovanie',
  ACTIVITY: 'vid_zanyatosti',
  MARTIAL: 'semeinoe_polozhenie',
  STEP_VARIETY: 'step_',
  ISSUE_DATE: 'data_vydachi_pasporta',
  PAYDAY: 'data_platezha',
  REFIN_REASON: 'prichina_refinansirovaniya',
  LOAN_READY: 'kredit_gotov_k_vydache',
  LOAN_SIGN: 'dogovor_kreditovaniya',
  SMS_CONFIRM: 'sms_podtverzhdenie',
}

export const EVENTS_THAT_CALLED_ONCE: EDataLayerEvents[] = [EDataLayerEvents.SHOW_STEP, EDataLayerEvents.PREFILL]

export const EDataLayerProductNameByProductId = {
  kredit_nalichnymi: 'kredit_nalichnymi',
  nan: 'net_produkta',
  kredit_refinansirovanie: 'refinansirovanie',
  ncpk: 'ncpk',
  cc_weekend_karma: 'mts_weekend_karma',
  kredit_kreditnye_kanikuly: 'kreditnye_kanikuly',
}

export const screenNamePrefixes = {
  MAIN: '/spa/',
  FULL_APPROVE: '/spa/loan/fa/',
  CREDIT_HOLIDAYS: '/spa/credit-holidays/',
}
