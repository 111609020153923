/**
 * Конфиг приложения из ЛК firebase
 */
export const firebaseAppConfig = {
  apiKey: 'AIzaSyCviXt--2-cI4S19HSuKG0EYiVdggOl6uI',
  authDomain: 'payment-mts-bank.firebaseapp.com',
  projectId: 'payment-mts-bank',
  storageBucket: 'payment-mts-bank.appspot.com',
  messagingSenderId: '734645606662',
  appId: '1:734645606662:web:d503e518c2d29b89b5a687',
  measurementId: 'G-1M2JB8G9SR',
}
