import React, { FC } from 'react'
import { GatewayProtoOpenedRequest } from '@open-api/ump/debit-cards'
import { Card } from './Card'
import { getStatusFilter, getStatusTranform, getStatusLink } from './utils'

type RequestCardsProps = {
  openedRequests: GatewayProtoOpenedRequest[]
}

export const RequestedCards: FC<RequestCardsProps> = ({ openedRequests }) => (
  <>
    {getStatusFilter(openedRequests)?.map(({ productId, statusCode, requestId }) => (
      <Card
        key={productId}
        name={productId}
        link={getStatusLink(statusCode, requestId)}
        status={getStatusTranform(statusCode)}
        statusCode={statusCode}
      />
    ))}
  </>
)
