import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FormatAliasPhone, formatPhoneNumber } from '@utils/formatPhoneNumber'
import { profileSelector } from '@selectors/auth'

interface Props {
  isGibInit: boolean
  isAuth: boolean
}
export const GibHOC: FC<Props> = ({ children, isGibInit, isAuth }) => {
  const { mobilePhone } = useSelector(profileSelector)

  useEffect(() => {
    if (isGibInit && isAuth && mobilePhone) {
      window.gib.setIdentityAndEncrypt(formatPhoneNumber(mobilePhone, FormatAliasPhone.DIGIT11))
      window.gib.setAuthStatus(window.gib.IS_AUTHORIZED)
    }
  }, [isAuth, isGibInit, mobilePhone])

  return <>{children}</>
}
