import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GatewayProtoErrorRsp, GatewayProtoGetShortAccountListRsp } from '@open-api/ump/debit-cards'
import { debitCardsApi } from '@api/ump/debit-cards'

import { AccountsState } from '@reducers/accounts/types'
import { Params } from '@root/types/reducers'
import { baseInitialState } from '@reducers/baseInitialState'

const NAMESPACE = 'accounts'

const initialState: AccountsState = {
  ...baseInitialState,
  isResent: false,
}

const fetchShortAccountList = createAsyncThunk<GatewayProtoGetShortAccountListRsp, Params>(
  `${NAMESPACE}/getShortAccountList`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await debitCardsApi.getShortAccountList('v1', 'WEB')

      return response.data
    } catch (err) {
      const errRes: GatewayProtoErrorRsp = err.response.data

      return rejectWithValue(errRes || { message: err.message })
    }
  }
)

const accounts = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchShortAccountList.pending, (state, action) => {
      if (action.meta?.arg?.isResent) {
        state.isResent = true

        return
      }

      state.inProgress = true
    })
    builder.addCase(fetchShortAccountList.rejected, (state, action) => {
      if (action.meta?.arg?.isResent) {
        state.isResent = false
      }

      state.error = action.payload
      state.inProgress = false
    })
    builder.addCase(fetchShortAccountList.fulfilled, (state, action) => {
      if (action.meta?.arg?.isResent) {
        state.isResent = false
      }

      state.inProgress = false
      state.data = action.payload
      state.error = null
    })
  },
})

export { fetchShortAccountList }
export const accountsReducer = accounts.reducer
